import add from "./add.svg";
import destination from "./destinationIcon.svg";
import edit from "./edit.svg";
import emptyArea from "./emptyArea.webp";
import emptyCardList from "./emptyCardlist.png";
import emptyTenant from "./emptyTenant.svg";
import myTravelBuddyLogo from "./mytravelbuddylogo.png";
import emptyLocation from "./emptyLocation.png";

export const img_add: string = add;
export const img_destination: string = destination;
export const img_edit: string = edit;
export const img_emptyArea = emptyArea;
export const img_emptyCardList: string = emptyCardList;
export const img_emptyTenant: string = emptyTenant;
export const img_myTravelBuddyLogo: string = myTravelBuddyLogo;
export const emptyLocationImage: string = emptyLocation;
