import { useContext, FC } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { TenantContext, UserContext } from "@mtb/mtb-core";
import { EnumUserRole, rolesCheck } from "@mtb/mtb-requests";
import { useTranslation } from "react-i18next";
import { Loader } from "@mtb/mtb-ui";

export type ProtectedRouteProps = {
  element: any;
  validRoles: EnumUserRole[];
};

// first do auth0 guard which will show login
export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  element,
  validRoles,
}) => {
  const reactElt = () => element; // workaround -> withAuthenticationRequired is giving an error
  const Component = withAuthenticationRequired(reactElt, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loader />
      </div>
    ),
  });
  // now return component, but first check roles
  const { t } = useTranslation("common");
  const { user } = useContext(UserContext);
  const { tenant } = useContext(TenantContext);

  // ... logged in -> if no rights, show error and goto tenants selection
  let authOk = user?.isAdminRole || false;
  if (!authOk && user) {
    let currentRole = EnumUserRole.GUEST; // in case no tenant, we are guest
    if (tenant) currentRole = tenant.userRoleInTenant as EnumUserRole;
    authOk = rolesCheck(currentRole, validRoles);
  }

  // ... if not enough rights, show alert
  if (!authOk) {
    if (user) {
      return <div>{t("general.protectedNotEnoughRights")}</div>;
    }
  }
  return <Component />;
};
