import { Center } from "@chakra-ui/react";
import React, { VFC } from "react";
import { LoaderType } from "./loaders";

import { loaders } from "./loaders";

export type LoaderProps = {
  type?: LoaderType;
};

export const Loader: VFC<LoaderProps> = ({ type = "default" }) => {
  const x = { ...loaders };
  const LoaderComponent = x[type];
  return (
    <Center width="100%" minHeight="300px" data-testid="load">
      <LoaderComponent />
    </Center>
  );
};
