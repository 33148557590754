import { Box, Link } from "@chakra-ui/layout";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const IconContainer = styled(Box)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
  // marginRight: isSideBarOpen ? 12px : 50px;
  height: 36px;
  width: 36px;
  border-radius: 8px;

  svg {
    opacity: 0.7;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.colors.primary};

      svg {
        opacity: 1;
      }
    `}
`;

export const StyledLink = styled(Link)`
  &:active,
  &:focus {
    outline: none;
    outline-offset: 0;
    box-shadow: none;
  }

  &:hover {
    .icon-container {
      transition: 0.2s;
      // TODO: add Polished library to use transparentize function, or another method
      background: ${({ theme }) => `${theme.colors.primary}`};
      opacity: 0.7;
    }
  }
`;
