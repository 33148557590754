import { Box } from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { DragEndEventHandlerFn, LatLng, Icon } from "leaflet";
import "./mapEditor.css";
import { useEffect } from "react";
import React from "react";
import {
  useMapEditorAddressLookup,
  MapEditorAddressLookupType,
} from "./useMapEditorLookUp";

type mapEditorProps = {
  width?: number | string;
  height?: number | string;
  markerName?: string;
  coordValue?: { Lat: number; Lng: number };
  onChange: (e: { Lat: number; Lng: number }) => void;
  addressLookup?: MapEditorAddressLookupType;
  addressLookupError?: (e: any) => void;
};

type MyComponentProps = {
  setPosition(arg: LatLng): void;
};

/*
  MapEditor component 
  Properties
    width?      sets the width of the map component
    height?     set the height of the map component
    markerName? name of the marker   
    coordValue  {Lat: xxx, Lng:xxx} location of the marker.
                in case blank, no marker is shown, map is position in central of europe zoom 4
                in case set, marker will be set and map will be centered and zoomed on the position
    onChange    Event handler for when the marker is moved or reset with click
    addressLookup Either pass a string, either pass an address component
                Based on the address, the position will be fetched from OSM and
                if found, the marker is set
    addressLookupError In case of a network or other error occurs while fetching
                coordinates of an address
*/
export const MapEditor: FC<mapEditorProps> = React.memo(
  ({
    width,
    height,
    markerName,
    coordValue,
    onChange,
    addressLookup,
    addressLookupError,
  }) => {
    const myRef: any = useRef(null);

    const [position, setPosition] = useState<LatLng | null>(null);
    const [center] = useState(
      coordValue
        ? new LatLng(coordValue.Lat, coordValue.Lng)
        : new LatLng(47.03, 7.42)
    );
    const markerRef = useRef<typeof Marker | any>(null);
    const { LookUpAddress } = useMapEditorAddressLookup();
    const dragMarkerHandler: DragEndEventHandlerFn = () => {
      const marker = markerRef.current;
      if (marker) {
        const val = marker.getLatLng();
        const newVal = { Lat: val.lat, Lng: val.lng };
        setPosition(marker.getLatLng());
        onChange(newVal);
      }
    };
    const defaultIcon = new Icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
    });

    function MyComponent({ setPosition }: MyComponentProps) {
      const map = useMapEvents({
        click: (e) => {
          const newVal = { Lat: e.latlng.lat, Lng: e.latlng.lng };
          setPosition(e.latlng);
          map.flyTo(e.latlng);
          onChange(newVal);
        },
      });
      //setMap(map);
      return null;
    }

    function setCoord(lat: number, lng: number) {
      if (lat && lng) {
        const newPos = new LatLng(lat, lng);
        if (myRef.current) myRef.current.flyTo(newPos, 15);
        setPosition(newPos);
      } else {
        console.log("setcoord invalid latlng", lat, lng);
      }
    }
    async function addressLookupPassed() {
      try {
        const result = await LookUpAddress(addressLookup!);
        if (result) {
          setCoord(result.Lat, result.Lng);
          onChange({ Lat: result.Lat, Lng: result.Lng });
        } else {
          if (addressLookupError) {
            addressLookupError("no address found");
          }
        }
      } catch (ex) {
        if (addressLookupError) {
          addressLookupError(ex);
        }
      }
    }

    // in case new value is passed, reset the centering
    useEffect(() => {
      if (coordValue) {
        if (
          coordValue.Lat !== position?.lat ||
          coordValue.Lng !== position?.lng
        ) {
          setCoord(coordValue.Lat, coordValue.Lng);
        }
      }
    }, [coordValue]);

    // in case adressLookup is passed, get the coordinates if possible
    useEffect(() => {
      if (addressLookup) {
        addressLookupPassed();
      }
    }, [addressLookup]);

    // hack to make sure map is rendered correctly
    // Has something todo with the modal ... ans its size not passed correctly
    //TODO Fix this hack if  better solution is found.
    // 21/07/2024 - seems this is not needed anymore. looks like import leaflet.css did the trick
    /*
    setTimeout(() => {
      if (myRef.current) {
        try {
          myRef.current.invalidateSize(true);
          //map.invalidateSize(true);
        } catch (err) {
          console.log("error in mapeditor", err);
        }
      }
    }, 1000);
*/
    return (
      <Box w={width} h={height}>
        <MapContainer
          center={center}
          zoom={coordValue ? 15 : 8}
          scrollWheelZoom={true}
          className="leaflet-container"
          ref={myRef}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MyComponent setPosition={setPosition} />
          {position ? (
            <Marker
              draggable={true}
              eventHandlers={{
                dragend: dragMarkerHandler,
              }}
              ref={markerRef}
              position={[position.lat, position.lng]}
              data-testid="testmarker"
              icon={defaultIcon}
            >
              <Popup>{markerName}</Popup>
            </Marker>
          ) : null}
        </MapContainer>
      </Box>
    );
  }
);
