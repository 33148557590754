/******************************************
 * area data using MTB-API
 * 22/01/2022 - Noël Thoelen - v1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import {
  ErrorCode,
  InSufficientTenantRights,
  InvalidTenantError,
  InvalidDestinationError,
  OptimisticLockError,
  DuplicateAliasError,
  InvalidAliasChangeError,
  DestinationHasRelatedDataError,
  IDestinationType,
  IDestinationListType,
  NotFoundError,
  FileUploadFailed,
} from "..";
import { appendIAssetTypeData } from "../helpers/appendIAssetTypeData";
import { appendMLToFormData } from "../helpers/appendMLToFormData";

export class DestinationService {
  /***************************************
   * @description Fetch destination overview for
   * @link : ../api/tenant/{tenantKey}/destination
   * @see http://localhost:3001/docs/#/destinations/GetDestinations
   *
   * @param tenantKey tenat to fetch data for
   * @param search Optional filter only destinations matching filter
   * @param areaId Optional filter on destinations for a specific area
   * @param destinationId Optional Only fetch info of specific destination
   *
   * @returns IDestinationListType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | not enoug rights to fetch the list
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getDestinationsOverviewForTenant(
    tenantKey: string,
    searchVal?: string,
    areaId?: string,
    destinationId?: number
  ): Promise<IDestinationListType[]> {
    try {
      console.log("Test now", areaId);
      let params = "";
      if (searchVal) {
        params = `search=${searchVal}`;
      }
      if (areaId) {
        params = `${params}${params ? "&" : ""}areaId=${areaId}`;
      }
      if (destinationId) {
        params = `${params}${
          params ? "&" : ""
        }destinationId=${destinationId.toString()}`;
      }
      const url = `/tenant/${tenantKey}/destination?${params}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const destInfo = json.data as IDestinationListType[];
      console.log("Cards overview destInfo", destInfo);

      return destInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description Fetch a specific destination
   * @link : ../api/tenant/{tenantKey}/destination/{destinationId}
   * @see http://localhost:3001/docs/#/destinations/GetDestination
   *
   * @param destinationId the destination to be fetched
   *
   * @returns IDestinationListType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | not enoug rights to fetch the info
   * - InvalidDestinationError | not found
   * - general Error | something went wrong (probably network issues)
   */
  public async getDestinationForTenant(
    tenantKey: string,
    destinationId?: number
  ): Promise<IDestinationType> {
    try {
      const url = `/tenant/${tenantKey}/destination/${destinationId}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const destInfo = json.data as IDestinationType;
      return destInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.NotFound:
          throw new InvalidDestinationError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description save destination with a specific Id or new if id is not provided
   * @link : ../api/tenant/{tenantKey}/destination/{destinationId}/{version}
   * @see http://localhost:3001/docs/#/destinations/SaveDestination
   *
   * @param tenantKey tenant where we will work on
   * @param data JSON object of type IDestinationType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | not enoug rights to fetch the info
   * - InvalidDestinationError | not found
   * - OptimisticLockError | another user changed the destination (optimistic lock)
   * - DuplicateAliasError | the alias is already in use
   * - InvalidAliasChangeError | the alias cannot be changed
   * - general Error | something went wrong (probably network issues)
   */
  public async saveDestinationForTenant(
    tenantKey: string,
    data: IDestinationType
  ) {
    try {
      let url = "";
      const formData = new FormData();
      formData.append("area[areaId]", data.area?.areaId.toString());
      formData.append("name", data.name);
      if (data.urbanisation) {
        if (data.urbanisation.id && data.urbanisation.id > -1) {
          formData.append("urbanisation[id]", data.urbanisation.id.toString());
        }
        formData.append("urbanisation[title]", data.urbanisation.title || "");
      }
      formData.append("alias", data.alias);
      data.street && formData.append("street", data.street);
      data.zipCode && formData.append("zipCode", data.zipCode);
      data.city && formData.append("city", data.city);
      data.appNo && formData.append("appNo", data.appNo);
      if (data.coordinates) {
        formData.append("coordinates[lat]", data.coordinates.lat.toString());
        formData.append("coordinates[lng]", data.coordinates.lng.toString());
      }
      appendIAssetTypeData(formData, data.image, "image");
      appendMLToFormData("welcomeText", formData, data.welcomeText);
      data.published &&
        formData.append("published", data.published?.toString());
      if (data.id) {
        url = `/tenant/${tenantKey}/destination/${data.id}/${data.version}`;
        const json: AxiosResponse = await mtbService.postPutFormDataProtected(
          "put",
          url,
          formData
        );
      } else {
        url = `/tenant/${tenantKey}/destination`;
        const json: AxiosResponse = await mtbService.postPutFormDataProtected(
          "post",
          url,
          formData
        );
      }
    } catch (errResponse: any) {
      console.log(errResponse);
      const errCode: ErrorCode = errResponse.response?.data
        ?.errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.NotFound:
          throw new InvalidDestinationError();
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        case ErrorCode.DuplicateAlias:
          throw new DuplicateAliasError();
        case ErrorCode.InvalidAliasChange:
          throw new InvalidAliasChangeError();
        case ErrorCode.FileUploadFailed:
          throw new FileUploadFailed();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description delete destination with a specific Id
   * @link : ../api/tenant/{tenantKey}/destination/{destinationId}/{version}
   * @see http://localhost:3001/docs/#/destinations/DeleteDestination
   *
   * @param tenantKey tenant where we will work on
   * @param destinationId id of the destination
   * @param version version of the destination
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | not enoug rights to fetch the info
   * - NotFoundError | not found
   * - OptimisticLockError | another user changed the destination (optimistic lock)
   * - DestinationHasRelatedDataError | the destination still has related data
   * - general Error | something went wrong (probably network issues)
   */
  public async deleteDestinationForTenant(
    tenantKey: string,
    destinationId: number,
    version: number
  ) {
    try {
      const url = `/tenant/${tenantKey}/destination/${destinationId.toString()}/${version.toString()}`;
      const json: AxiosResponse = await mtbService.deleteProtected(url);
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        case ErrorCode.RecordHasRelatedData:
          throw new DestinationHasRelatedDataError();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
