import { EnumUserRole } from "@mtb/mtb-requests";

export const tenantRolePermissions = [
  EnumUserRole.ADMIN,
  EnumUserRole.TENANTADMIN,
  EnumUserRole.TENANTCONTRIBUTOR,
  EnumUserRole.OWNER,
];

export const adminAppRolePermissions = [
  EnumUserRole.ADMIN,
  EnumUserRole.TENANTADMIN,
  EnumUserRole.TENANTCONTRIBUTOR,
  EnumUserRole.OWNER,
  EnumUserRole.GUEST,
];
