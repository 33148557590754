import { Box } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { StepStateType } from "./types";

export type StepProps = {
  title: string;
  subTitle: string;
  stepState: StepStateType;
};

export const Step: FC<PropsWithChildren<StepProps>> = ({
  title,
  subTitle,
  children,
}) => {
  return <Box pt="10">{children}</Box>;
};
