import React from "react";

export type DestinationType = {
  id: number;
  title: string;
  address?: string;
};

export type DestinationContextType = {
  destination: DestinationType | null;
  setDestination: (destination: DestinationType | null) => void;
};

export const DestinationContext = React.createContext<DestinationContextType>({
  destination: null,
  setDestination: () => {},
});
