/******************************************
 * tenant data using MTB-API
 * 03/08/2021 - Noël Thoelen - v1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import {
  ErrorCode,
  ITenantsForUserResponse,
  ITenantInfoType,
  IAppDataType,
  NotFoundError,
} from "..";

export class InvalidTenantError extends Error {}
export class InSufficientTenantRights extends Error {}

export class TenantService {
  /**************************************
   * @description get info for a specific tenant
   * @link : ../api/tenant/{key}
   * @see http://localhost:3001/docs/#/tenant/GetTenantInfo
   *
   * @param tenantKey tenant key of the tenant to be fetched
   * @Returns ITenantInfoType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - **NotFoundError** | unknown tenant
   * - **general Error** | something went wrong (probably network issues)
   */
  public async getTenantInfo(
    tenantKey: string
  ): Promise<ITenantInfoType | void> {
    try {
      const json: AxiosResponse = await mtbService.getProtected(
        `/tenant/${tenantKey}`
      );
      const tenantInfo = json.data as ITenantInfoType;
      return tenantInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        default:
          break;
      }
      throw new Error((errResponse as Error).message);
    }
  }

  /**************************************
   * @description get all tenants this user is involved in
   * @link : ../api/user/GetTenantsForUser
   * @see http://localhost:3001/docs/#/Configuration/GetTenantsForUser
   *
   * @param searchVal Optional string used as search key to filter list of tenants
   * @Returns ITenantsForUserResponse
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - **general Error** | something went wrong (probably network issues)
   */
  public async getTenantsForUser(
    searchVal?: string
  ): Promise<ITenantsForUserResponse | void> {
    try {
      let searchParam = "";
      if (searchVal) {
        searchParam = `?searchVal=${searchVal}`;
      }
      const json: AxiosResponse = await mtbService.getProtected(
        `/user/getTenantsForUser${searchParam}`
      );
      const tenants = json.data as ITenantsForUserResponse;
      return tenants;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        default:
          break;
      }
      throw new Error((errResponse as Error).message);
    }
  }

  /***************************************
   * @description Using this method, the guestApp can fetch all relative data
   *    to fill the context containing all relative info for the app to work
   * @link : ../api/tenant/kkk/area (post/put)
   * @see http://localhost:3001/docs/#/appInfo/GetAppData
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @param destinationId destinationId where the app in initalized for
   * @param tripCode Optional - a trip code
   * @Returns IAppData
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | a token needs to be provided
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getTenantAppData(
    tenantKey: string,
    alias?: string,
    tripCode?: string
  ): Promise<IAppDataType> {
    try {
      let json: AxiosResponse;
      if (!tripCode) {
        json = await mtbService.getProtected(
          `/tenant/${tenantKey}/appInfo?alias=${alias}`
        );
      } else {
        json = await mtbService.getProtected(
          `/tenant/${tenantKey}/appInfo?tripcode=${tripCode}`
        );
      }
      const appData = json.data as IAppDataType;
      return appData;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw new Error((errResponse as Error).message);
    }
  }
}
