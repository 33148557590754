import translationDE from "./locales/de/common.json";
import translationEN from "./locales/en/common.json";
import translationES from "./locales/es/common.json";
import translationFR from "./locales/fr/common.json";
import translationNL from "./locales/nl/common.json";
import tenantAdminDE from "./locales/de/tenants.json";
import tenantAdminEN from "./locales/en/tenants.json";
import tenantAdminES from "./locales/es/tenants.json";
import tenantAdminFR from "./locales/fr/tenants.json";
import tenantAdminNL from "./locales/nl/tenants.json";
import areaAdminDE from "./locales/de/areas.json";
import areaAdminEN from "./locales/en/areas.json";
import areaAdminES from "./locales/es/areas.json";
import areaAdminFR from "./locales/fr/areas.json";
import areaAdminNL from "./locales/nl/areas.json";
import destinationAdminDE from "./locales/de/destinations.json";
import destinationAdminEN from "./locales/en/destinations.json";
import destinationAdminES from "./locales/es/destinations.json";
import destinationAdminFR from "./locales/fr/destinations.json";
import destinationAdminNL from "./locales/nl/destinations.json";
import infoCardsDE from "./locales/de/infoCards.json";
import infoCardsEN from "./locales/en/infoCards.json";
import infoCardsES from "./locales/es/infoCards.json";
import infoCardsFR from "./locales/fr/infoCards.json";
import infoCardsNL from "./locales/nl/infoCards.json";


export const resources = {
  de: {
    common: translationDE,
    tenants: tenantAdminDE,
    areas: areaAdminDE,
    destinations: destinationAdminDE,
    infoCards: infoCardsDE,
  },
  en: {
    common: translationEN,
    tenants: tenantAdminEN,
    areas: areaAdminEN,
    destinations: destinationAdminEN,
    infoCards: infoCardsEN,
  },
  es: {
    common: translationES,
    tenants: tenantAdminES,
    areas: areaAdminES,
    destinations: destinationAdminES,
    infoCards: infoCardsES,
  },
  fr: {
    common: translationFR,
    tenants: tenantAdminFR,
    areas: areaAdminFR,
    destinations: destinationAdminFR,
    infoCards: infoCardsFR,
  },
  nl: {
    common: translationNL,
    tenants: tenantAdminNL,
    areas: areaAdminNL,
    destinations: destinationAdminNL,
    infoCards: infoCardsNL,
  },
};
