import { Tabs, TabType } from "@mtb/mtb-ui";
import { TenantContext } from "../../tenantContext";
import { useTheme } from "@chakra-ui/react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { EnumLanguages } from "@mtb/mtb-requests";

/**
 * LanguageIcon Component
 * Displays an icon for language-specific tabs validation in a React Hook Form-based Tabs component.
 * Determines whether mandatoryFields contain valid data for a specific language to show the icon.
 */
const LanguageIcon: FC<{ lng: EnumLanguages; fieldsToWatch: string[] }> = ({
  lng,
  fieldsToWatch,
}) => {
  const theme = useTheme();

  // Generate fields based on language
  const fields = fieldsToWatch.map((field) => `${field}.${lng}`);

  // Watch content changes
  const contentToWatch = useWatch({ name: fields });

  // Check if icon should be displayed based on content changes
  let showIcon = true; // Initialize as true, assuming all fields have values
  contentToWatch.forEach((value) => {
    // Check if content is an array or non-empty paragraph from a text editor or empty string

    // Array type for the tags
    if (Array.isArray(value)) {
      if (value.length === 0) {
        showIcon = false;
      }
      // non-empty paragraph from the text editor
    } else if (value && value === "<p><br></p>") {
      showIcon = false;
      // empty string for normal input
    } else if (!value) {
      showIcon = false;
    }
  });

  // Render the icon if showIcon is true (i.e., all fields have values), otherwise render nothing
  if (showIcon) {
    return (
      <CheckCircleIcon
        ml={2}
        w={4}
        h={4}
        color={theme.colors.primary}
        data-testid={`theicon.${lng}`}
      />
    );
  } else {
    return null;
  }
};

/**
 * MultilingualTabs Component
 * Displays multilingual tabs based on supported languages and mandatory fields.
 * Utilizes LanguageIcon component for each language to show validation icons.
 */

type MultilingualTabsProps = {
  title: string;
  mandatoryFields?: string[];
  children: React.ReactNode;
};

export const MultilingualTabs: FC<MultilingualTabsProps> = ({
  children,
  title,
  mandatoryFields = [],
}) => {
  const [languageTabs, setLanguageTabs] = useState<TabType[]>([]);

  const { tenant } = useContext(TenantContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (tenant?.supportedLngs) {
      // Remove duplicates from supportedLngs
      const uniqueLanguages = [...new Set(tenant.supportedLngs)];
      const tabs: TabType[] = [];

      // Always add the fallback language first
      const fallbackLabel =
        mandatoryFields.length > 0
          ? `${t(`languages.${tenant.fallBackLanguage}`)} *`
          : t(`languages.${tenant.fallBackLanguage}`);

      tabs.push({
        label: fallbackLabel,
        icon:
          mandatoryFields.length > 0 ? (
            <LanguageIcon
              lng={tenant.fallBackLanguage}
              fieldsToWatch={mandatoryFields}
            />
          ) : null,
      });

      // Add the rest of the supported languages, excluding the fallback language if it's already added
      uniqueLanguages.forEach((language) => {
        // Avoid adding the fallback language twice
        if (language !== tenant.fallBackLanguage) {
          tabs.push({
            label: t(`languages.${language}`),
            icon:
              mandatoryFields.length > 0 ? (
                <LanguageIcon lng={language} fieldsToWatch={mandatoryFields} />
              ) : null,
          });
        }
      });

      setLanguageTabs(tabs);
    }
  }, [tenant?.supportedLngs, tenant?.fallBackLanguage, mandatoryFields, t]);

  return (
    <Tabs title={title} tabs={languageTabs}>
      {children}
    </Tabs>
  );
};
