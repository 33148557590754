import { Box, chakra, Divider, useMediaQuery } from "@chakra-ui/react";
import { Button, HStack } from "@chakra-ui/react";
import React, { FC, useMemo, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.css";

interface TextEditorProps {
  onChange: (value: string) => void;
  value: string;
  id?: string;
  height?: string;
  error?: boolean;
}

//TODO How to make a chakra component of this so it also get the focus border etc?
//export const TextEditor:FC<TextEditorProps> = React.forwardRef<ReactQuill, TextEditorProps>(({id = "0", name, onChange, onBlur}, ref) => {
export const TextEditor: FC<TextEditorProps> = ({
  onChange,
  value,
  id = "0",
  height,
  error,
}) => {
  const [isLargerThen450] = useMediaQuery("(min-width: 450px)");

  const formats = useMemo(
    () => [
      "bold",
      "italic",
      "underline",
      "strike",
      "script",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "align",
      "font",
      "header",
      "color",
      "background",
      "size",
    ],
    []
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#toolbar_${id}`,
      },
    }),
    []
  );
  const customToolbar = useMemo(() => {
    if (isLargerThen450) {
      return (
        <HStack
          id={`toolbar_${id}`}
          isInline
          spacing="1px"
          backgroundColor="gray.200"
          borderTopRadius="md"
        >
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
          <Divider orientation="vertical" width="10px" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
          <select className="ql-align">
            <option value="" />
            <option value="center" />
            <option value="right" />
            <option value="justify" />
          </select>
          <Divider orientation="vertical" width="10px" />
          <button className="ql-header" value="1" />
          <button className="ql-header" value="2" />
          {/*
          <select className="ql-size" defaultValue="medium">
            <option value="extra-small">Size 1</option>
            <option value="small">Size 2</option>
            <option value="medium">Size 3</option>
            <option value="large">Size 4</option>
          </select>
          */}
          <button className="ql-blockquote" />
          <Divider orientation="vertical" width="10px" />
          <select className="ql-color" />
          <select className="ql-background" />
          <button className="ql-link" />
        </HStack>
      );
    } else {
      return (
        <HStack
          id={`toolbar_${id}`}
          isInline
          spacing="1px"
          backgroundColor="gray.200"
          borderTopRadius="md"
        >
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <Divider orientation="vertical" width="10px" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <Divider orientation="vertical" width="10px" />
          <button className="ql-link" />
        </HStack>
      );
    }
  }, [isLargerThen450]);
  const customToolbar2 = (
    <div id={`toolbar_${id}`} hidden={false}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-blockquote" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <select className="ql-align">
        <option value="" />
        <option value="center" />
        <option value="right" />
        <option value="justify" />
      </select>
      <select className="ql-mylinks">
        <option value="a1">yutyyu</option>
        <option value="a2">Een 2e link</option>
      </select>
    </div>
  );
  const handleEditorChange = (value: string) => {
    const normalizedValue = value === "<p><br></p>" ? "" : value;
    onChange(normalizedValue);
  };

  return (
    <Box
      style={
        error
          ? {
              border: "1px solid #E53E3E ",
              boxShadow: "0 0 0 1px #e53e3e",
              borderRadius: "8px",
            }
          : {}
      }
    >
      {customToolbar}
      <ReactQuill
        theme="snow"
        defaultValue={value}
        value={value}
        formats={formats}
        modules={modules}
        onChange={handleEditorChange}
        style={{ height: height }}
      />
    </Box>
  );
};
