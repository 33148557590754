import { FC } from "react";
import { Box, Center, Flex, Text, useTheme } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { WarningIcon } from "@chakra-ui/icons";

import { StepStateType } from "./types";

type stepIconProps = {
  title: string;
  subTitle: string;
  lastItem: boolean;
  firstItem: boolean;
  stepState: StepStateType;
  stepNbr: number;
  clickHandler: (stepNbr: number) => void;
  currentStep: number;
  currentStepState: StepStateType | undefined;
};

/*
 * StepIcon
 */
export const StepIcon: FC<stepIconProps> = ({
  title,
  subTitle,
  firstItem,
  lastItem,
  stepState,
  stepNbr,
  clickHandler,
  currentStep,
  currentStepState,
}) => {
  const theme = useTheme();

  const itemClicked = () => {
    if (stepState !== "todo" && currentStep !== stepNbr) {
      clickHandler(stepNbr);
    }
  };

  let bgColor;
  let color;

  switch (stepState) {
    case "current":
      bgColor = theme.colors.secondary;
      color = theme.colors.text.onSecondary;
      break;
    case "todo":
      bgColor = theme.colors.backgrounds.terciary;
      color = theme.colors.text.onTerciary;
      break;
    case "done":
      bgColor = theme.colors.primary;
      color = theme.colors.text.onPrimary;
      break;
    case "error":
      bgColor = theme.colors.backgrounds.error;
      color = theme.colors.text.onError;
      break;
    default:
      break;
  }

  return (
    <Flex flexDirection="column" flex="1">
      <Flex flexDirection="row" flex="1" onClick={itemClicked}>
        {firstItem && <Box flex="1" />}
        {!firstItem && (
          <Box
            border="2px"
            flex="1"
            h="2px"
            bgColor={bgColor}
            borderRightRadius="sm"
            color={bgColor}
            mt="3"
          />
        )}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Center
            bgColor={bgColor}
            borderRadius="3xl"
            h="35px"
            w="35px"
            mb="2"
            fontWeight="bold"
            _hover={{
              cursor:
                currentStep !== stepNbr &&
                stepState !== "todo" &&
                currentStepState !== "error"
                  ? "pointer"
                  : "default",
            }}
          >
            {stepState === "current" && <Box color={color}>{stepNbr}</Box>}
            {stepState === "todo" && <Box color={color}>{stepNbr}</Box>}
            {stepState === "done" && <CheckIcon color={color} />}
            {stepState === "error" && <Box color={color}>!</Box>}
          </Center>
          <Flex
            flexDirection="column"
            alignItems="center"
            flex="1"
            opacity={stepState === "todo" ? 0.2 : 1}
          >
            <Text color={theme.colors.text.secondary}>{title}</Text>
            <Text fontWeight="bold" color={theme.colors.text.primary}>
              {subTitle}
            </Text>
          </Flex>
        </Box>

        {lastItem && <Box flex="1" />}
        {!lastItem && (
          <Box
            border="2px"
            flex="1"
            h="2px"
            color={bgColor}
            borderLeftRadius="sm"
            bgColor={bgColor}
            mt="3"
          />
        )}
      </Flex>
    </Flex>
  );
};
