import { FC, PropsWithChildren, useState } from "react";

import { TenantType } from "./types";
import { TenantContext } from "./TenantContext";

export const TenantProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tenant, setTenantContext] = useState<TenantType | null>(null);

  const setTenant = (tenant: TenantType | null) => {
    console.log("in setTenant", tenant);
    if (tenant) {
      let tenantAppUrl = new URL(
        tenant.tenantKey + "/",
        process.env.NX_REACT_APP_URL!.toString()
      ).toString();
      if (tenant.tenantAppUrl) {
        tenantAppUrl = tenant.tenantAppUrl;
      }
      // ...make sure the fallback language is the first in the supported languages array
      const supportedLngs = [
        tenant.fallBackLanguage,
        ...tenant.supportedLngs.filter(
          (elt) => elt !== tenant.fallBackLanguage
        ),
      ];
      console.log("Test today", {
        ...tenant,
        supportedLngs,
        tenantAppUrl,
      });

      setTenantContext({
        ...tenant,
        supportedLngs,
        tenantAppUrl,
      });
    } else {
      setTenantContext(null);
    }
  };

  const contextValue = {
    tenant,
    setTenant,
  };

  return (
    <TenantContext.Provider value={contextValue}>
      {children}
    </TenantContext.Provider>
  );
};
