import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Flex, FormHelperText, FormLabel, useTheme } from "@chakra-ui/react"
import React, { FC, useState } from "react"

type formLabelWithHelpProps = {
  labelText: string;
  helpText: string;
}

export const FormLabelWithHelp:FC<formLabelWithHelpProps> = ({labelText, helpText}) => {
  const [helpTextVisible, setHelpTextVisible] = useState(false)
  const theme = useTheme();
  return(
    <React.Fragment>
      <Flex alignItems="center">
        <FormLabel htmlFor="urbanisation">{labelText}</FormLabel>
        <InfoOutlineIcon onClick={() => setHelpTextVisible(!helpTextVisible)} mb="8px" color={theme.colors.text.tertiary} />
      </Flex>
      {helpTextVisible && <FormHelperText mb="5px">{helpText}</FormHelperText>}
    </React.Fragment>
  )
}