import React from "react";

import { ICountryType } from "@mtb/mtb-requests";

export type AreaType = {
  areaId: number;
  title: string;
  countryCode: ICountryType;
  publishedDestinations: number;
  unpublishedDestinations: number;
};
export type AreaContextType = {
  area: AreaType | null;
  loading: Boolean;
  setArea: (area: AreaType | null) => void;
};

export const AreaContext = React.createContext<AreaContextType>({
  area: null,
  loading: false,
  setArea: () => {},
});
