import axios, {AxiosResponse, AxiosError} from "axios";

export type MapEditorAddressLookupType = string | {
  street: string; 
  postalCode: string;
  city: string;
  country: string;
}

export const useMapEditorAddressLookup = () => {
  async function LookUpAddress (address: MapEditorAddressLookupType) {
    let url = ""
    if (typeof address === "string") {
      url = `https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${address}`
    } else {
      url = `https://nominatim.openstreetmap.org/search?format=json&limit=1&street=${address.street}&city=${address.city}&postalcode=${address.postalCode}&country=${address.country}`
    }
    const json:AxiosResponse = await axios.get(url)
    if (json.data.length > 0) {
      return {
        Lat: Number(json.data[0].lat),
        Lng: Number(json.data[0].lon)
      }
    } else {
      return null
    }
  }

  return {
    LookUpAddress
  }
}