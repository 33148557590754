import { IAssetType } from "@mtb/mtb-requests";
import { AssetType } from "@mtb/mtb-ui";

export const transform_IAssetType_To_AssetType = (
  apiImage: IAssetType | undefined,
  altText?: string
): AssetType | undefined => {
  if (!apiImage) return undefined;
  return {
    id: apiImage.id,
    mimeType: apiImage.mimeType,
    url: apiImage.url,
    variant: { ...apiImage.variant },
    altText: altText,
  };
};
