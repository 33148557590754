/******************************************
 * authenticate using MTB-API
 * @Author Noël Thoelen - v1
 * @version 1
 *
 * 09/07/2021 - Noël Thoelen - added logout method
 * 09/07/2021 - Noël Thoelen - added LoginUsingRefreshToken
 * 22/04/2024 - Noël Thoelen - We are replacing the build in MTB authentication with auth0.
 *   However, we also change the architecture so that we create a separate IAMProvider library. So, for now we are creating
 *   the auth0IAMProvider, but if for some reason we decide in the future to build IAM ourselves again, we are storing
 *   the old code we did create now in the MTBAIMProvider. See the MTBIAMProvider for more info
 */
import { AxiosResponse } from "axios";
import { mtbService } from "./mtbService";
import {
  ErrorCode,
  InvalidDestinationAliasError,
  InvalidTenantError,
  InvalidTokenError,
  InvalidTripCodeError,
} from "../entities/Error/ErrorCodes";
import { ISwitchTenantPayload } from "../entities/DTO-models/Authentication/ISwitchTenantPayload";
import { ISwitchTenantResponse } from "../entities/DTO-models/Authentication/ISwitchTenantResponse";
import { IGetGuestTokenPayload } from "../entities/DTO-models/Authentication/IGetGuestTokenPayload";
import { IGuestTokenResponse } from "../entities/DTO-models/Authentication/IGuestTokenResponse";

export class AuthenticateService {
  constructor(accessToken?: string) {
    mtbService.setAuth0AccessToken(accessToken);
  }

  /***************************************
   * @description Switch tenant - notice that the user should have admin rights in the new tenant
   * @link : ../api/auth/switchtenant
   * @see http://localhost:3001/docs/#/Authentication/SwitchTenant
   *
   * @param tenantKey newTenant alias to switch to
   * @Returns ISwitchTenantResponse
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InvalidTokenError | token is (no longer) valid
   * - InvalidTenantError | not a valid tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async SwitchTenant(tenantKey: string): Promise<ISwitchTenantResponse> {
    const payload: ISwitchTenantPayload = {
      newTenantKey: tenantKey,
    };

    try {
      const resp: ISwitchTenantResponse = (await mtbService.postSwitchTenant(
        payload
      )) as ISwitchTenantResponse;
      return resp;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.TokenInvalid:
          throw new InvalidTokenError();
        default:
          break;
      }
      throw new Error((errResponse as Error).message);
    }
  }

  /***************************************
   * @description get a token for an anonimous guest
   * @link : ../api/auth/getGuestToken
   * @see http://localhost:3001/docs/#/Authentication/GetGuestToken
   *
   * @param host specific bought hostname
   * @param tenant tenant alias for the tenant we want to continue
   * @param alias Optional the destination alias we want to acces
   * @param tripCode Optional a trip code we want to acces
   * @Returns IGuestTokenResponse
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InvalidTokenError | token is (no longer) valid
   * - InvalidTenantError | not a valid tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getGuestToken(
    host: string,
    tenant: string
  ): Promise<IGuestTokenResponse> {
    const payload: IGetGuestTokenPayload = {
      host: host,
      tenant: tenant,
    };

    try {
      const json: AxiosResponse = await mtbService.postGetGuestToken(payload);
      const guestResponse = json.data as IGuestTokenResponse;
      mtbService.setAuth0AccessToken(guestResponse.token);
      return guestResponse;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.InvalidDestinationAlias:
          throw new InvalidDestinationAliasError();
        case ErrorCode.InvalidTripCode:
          throw new InvalidTripCodeError();
      }
      throw new Error((errResponse as Error).message);
    }
  }

  public setAuth0AccessToken(accessToken: string): void {
    mtbService.setAuth0AccessToken(accessToken);
  }
}
