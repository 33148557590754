import React, { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { StepIcon } from "./stepIcon";
import { Step, StepProps } from "./step";

type stepperProps = {
  currentStep: number;
  onStepClick: (stepNbr: number) => void;
  children: React.ReactElement[];
};

export const Stepper: FC<stepperProps> = ({
  currentStep,
  onStepClick,
  children,
}) => {
  const childItems = React.Children.toArray(children);
  const steps: React.ReactElement<StepProps>[] = [];

  childItems.forEach((child: any) => {
    if (child.type.name === "Step") {
      steps.push(child);
    }
  });

  return (
    <>
      <Flex flexDirection="row" justifyContent="space-evenly">
        {steps.map((item, index) => (
          <StepIcon
            key={index.toString()}
            title={item.props.title}
            subTitle={item.props.subTitle}
            firstItem={index === 0}
            lastItem={index === steps.length - 1}
            stepNbr={index + 1}
            stepState={item.props.stepState}
            clickHandler={onStepClick}
            currentStep={currentStep}
            currentStepState={
              currentStep === index + 1 ? item.props.stepState : undefined
            }
          />
        ))}
      </Flex>
      <Box>{steps[currentStep - 1]}</Box>
    </>
  );
};
