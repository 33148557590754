import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

import { defaultMTBTheme, breakpoints } from "@mtb/mtb-style";
import { TenantProvider } from "@mtb/mtb-core";
import { AlertsAPIProvider, AlertsAPI, UserProvider } from "@mtb/mtb-core";
// import "@fontsource-variable/dm-sans";

import { AppRoutes } from "./routes";
import { UserContextLoader } from "./routes/UserContextLoader";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";
import { TenantContextLoader } from "./routes/TenantContextLoader";
import { AdminLayout } from "./common/components/layout/AdminLayout";

const theme = extendTheme({ ...defaultMTBTheme, breakpoints });

export const App = () => (
  <ThemeProvider theme={defaultMTBTheme}>
    <ChakraProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <UserProvider>
          <TenantProvider>
            <AlertsAPIProvider>
              <AlertsAPI />
              <BrowserRouter>
                <Auth0ProviderWithNavigate>
                  <AdminLayout>
                    <UserContextLoader>
                      <TenantContextLoader>
                        <AppRoutes />
                      </TenantContextLoader>
                    </UserContextLoader>
                  </AdminLayout>
                </Auth0ProviderWithNavigate>
              </BrowserRouter>
            </AlertsAPIProvider>
          </TenantProvider>
        </UserProvider>
      </I18nextProvider>
    </ChakraProvider>
  </ThemeProvider>
);
