import codes from "./languages.json";

const allLanguagesCodes: {
  [key: string]: {
    name: string;
    nativeName: string;
  };
} = codes;

export const useLanguagesHook = () => {
  const allExistingLanguagesOptions: {
    label: string;
    value: string;
  }[] = [];
  Object.keys(allLanguagesCodes).forEach((key: string) =>
    allExistingLanguagesOptions.push({ label: allLanguagesCodes[key].name, value: key })
  );

  return {
    allExistingLanguagesOptions,
  };
};
