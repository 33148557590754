import React, { ReactNode, FC } from "react";
import ReactSelect, { components, SingleValue } from "react-select";
import SelectIcon from "./SelectIcon";
import { useTheme } from "@chakra-ui/react";
import { OptionType } from "./types";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIcon />
    </components.DropdownIndicator>
  );
};

type SelectProp = {
  name: string;
  value: OptionType;
  placeholder?: string;
  options: OptionType[];
  onChange: (selectedOption: any) => void;
  styles?: any;
  isSearchable?: boolean;
};

/** Select component */
export const Select: FC<SelectProp> = React.memo(
  ({
    name = undefined,
    options,
    onChange,
    value = undefined,
    styles = {},
    placeholder = undefined,
    isSearchable = true,
    ...props
  }) => {
    const onOptionChange = (selectedOption: SingleValue<OptionType>) => {
      onChange(selectedOption);
    };
    const theme = useTheme();

    const customStyles = {
      ...styles,
      option: (base: any, state: any) => ({
        position: "relative",
        padding: "0.6rem",
        borderBottom: `.1rem solid lightgray`,
        // backgroundColor: theme.colors.backgrounds.secondary,
        // fontWeight: theme.font.weights.medium,
        fontSize: theme.fontSizes.sm,
        whiteSpace: "nowrap",
        // color: theme.font.colors.primary,
        ":last-child": {
          borderBottom: "none",
        },
        ":hover": {
          //   color: theme.font.colors.accent,
          cursor: "pointer",
        },
        ":active": {
          // border: `.1rem solid ${theme.colors.primary[600]}`,
        },
        ...(styles.option && styles.option()),
      }),
      control: (provided: any) => ({
        ...provided,
        width: "100%",
        minWidth: "6rem",
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.fontWeights.normal,
        boxShadow: "none",
        ...(styles.control && styles.control()),
        ":hover": {
          border: "none",
          boxShadow: "none",
        },
      }),
      menu: (provided: any) => ({
        ...provided,
        boxShadow: "none",
        width: "auto",
        right: 0,
        ...(styles.menu && styles.menu()),
      }),
      menuList: () => ({
        minWidth: "6rem",
        padding: "0",
        // border: `.1rem solid ${theme.colors.borders.primary}`,
        borderRadius: ".3rem",
        boxShadow: `0 .1rem 2rem lightgray`,
        overflowX: "hidden",

        /* Firefox scrollbar styling */
        mozScrollbarWidth: "thin",
        scrollbarWidth: "thin",
        // scrollbarColor: `${theme.colors.borders.inactive}`,

        /* Chrome scrollbar styling */
        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "none",
          borderRadius: "0.8rem",
          backgroundColor: "transparent",
        },
        " &::-webkit-scrollbar": {
          width: "0.8rem",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "0.8rem",
          webkitBoxShadow: "none",
          //   backgroundColor: `${theme.colors.borders.inactive}`,
        },

        ":before": {
          content: '""',
          display: "block",
          position: "absolute",
          right: "1em",
          bottom: "calc(100% - .5em)",
          height: "0",
          width: "0",
          border: ".5em outset transparent",
          // eslint-disable-next-line prettier/prettier
          //   borderColor: `${theme.colors.borders.primary} transparent transparent ${theme.colors.borders.primary}`,
          //   boxShadow: `0 .1rem 2rem ${theme.colors.borders.primary}`,
          transform: "rotate(45deg)",
          zIndex: "0",
        },
        ":after": {
          content: '""',
          display: "block",
          position: "absolute",
          right: "1em",
          bottom: "calc(100% - .5em - .1rem)",
          height: "0",
          width: "0",
          border: ".5em outset transparent",
          // eslint-disable-next-line prettier/prettier
          //   borderColor: `${theme.colors.backgrounds.secondary} transparent transparent ${theme.colors.backgrounds.secondary}`,
          transform: "rotate(45deg)",
          zIndex: "2",
        },
        ...(styles.menuList && styles.menuList()),
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        justifyContent: "flex-end",
        paddingRight: 0,
      }),
    };

    return (
      <ReactSelect
        data-test="select_component"
        name={name}
        value={value}
        options={options}
        onChange={(selectedOption: SingleValue<OptionType>) => {
          onOptionChange(selectedOption);
        }}
        styles={customStyles}
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        isSearchable={isSearchable}
        {...props}
      />
    );
  }
);
