export const formatShortDate = (i18nLang: string, theDate: Date):string => {
  let locale = '';
  switch (i18nLang) {
    case 'nl': 
      locale='nl-BE'
      break;
    case 'de': 
      locale='de-DE'
      break;
    case 'fr': 
      locale='fr-FR'
      break;
    case 'es': 
      locale='es-ES'
      break;
    default:
      locale='en-EN'
      break;
  }
  return theDate.toLocaleDateString(locale)
}