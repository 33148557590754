import { Box, Center } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { FC } from "react";

export type ErrorProps = {
  message: string;
};

export const Error: FC<ErrorProps> = ({ message }) => {
  return (
    <Box>
      <Center>
        <WarningTwoIcon boxSize="2em" color="#36425a" mb="2" />
      </Center>
      <Center width="80%" margin="0 auto" textAlign="center">
        {message}
      </Center>
    </Box>
  );
};
