import { Theme } from "./types/theme";
import { Global } from "@emotion/react";

import { SystemStyleFunction, mode } from "@chakra-ui/theme-tools";

const Button = {
  variants: {
    solid: {
      bg: "#07BAA0",
      color: "#FFF",
      borderRadius: "xl",
      // shadow: "0 .125rem .25rem rgb(7 186 160 / 70%)",
      _hover: { bg: "#07BAA0", color: "white", _disabled: { bg: "#07BAA0" } },
      _active: { bg: "#07BAA0", color: "white" },
      padding: "16px 40px",
      height: "34px",
    },
    outline: {
      color: "#07BAA0",
      border: "1.5px solid",
      borderRadius: "12px",
      padding: "16px 40px",
      height: "34px",
    },
    // outline: {
    //   color: "#07BAA0",
    //   borderColor: "#07BAA0",
    //   borderRadius: "xl",
    //   _hover: { bg: "#FFF" },
    // },
    link: {
      color: "#07BAA0",
    },
  },
};
const FormLabel = {
  baseStyle: {
    color: "#556075",
  },
};

const Checkbox = {
  baseStyle: {
    control: {
      _checked: {
        bg: "#07BAA0",
        borderColor: "#07BAA0",
      },
    },
  },
};

import { createBreakpoints } from "@chakra-ui/theme-tools";

export const breakpoints = createBreakpoints({
  sm: "320px",
  md: "576px",
  lg: "768px",
  xl: "992px",
  "2xl": "1200px",
  "3xl": "1850px",
});

export const overrides = {
  fonts: {
    body: `'DM Sans Variable', sans-serif`,
    heading: `'DM Sans Variable', sans-serif`,
  },
  styles: {
    global: {
      body: {
        lineHeight: 1.3,
      },
    },
  },
};

export const defaultMTBTheme: Theme = {
  name: "default",
  colors: {
    primary: "#07BAA0",
    secondary: "#36425B",
    backgrounds: {
      primary: "#fff",
      secondary: "#fbfcfd",
      terciary: "#E5E7EA",
      quaternary: "#ffffff80",
      error: "#f1cdcd",
    },
    text: {
      primary: "#13161D", // used for most text
      secondary: "#ADB2BC", // used for text which is lower in the visual hierarchy
      tertiary: "#E5E7EA",
      quaternary: "#afb4bd",
      onPrimary: "#FFF",
      onSecondary: "#FFF",
      onTerciary: "#b8b9c1",
      onError: "#ba0808",
    },
    borders: {
      veryLight: "rgb(255 255 255 / 20%)",
      light: "#eff1f5",
      strong: "#000",
    },
  },
  shadows: {
    // card: "0 .125rem .25rem rgba(0,0,0,.075)",
    card: "0 .5rem 1rem rgba(0,0,0,.1)",
    primaryButton: "0 .125rem .25rem rgb(7 186 160 / 70%)",
  },
  components: {
    Button: Button,
    FormLabel: FormLabel,
    Checkbox: Checkbox,
  },
};
