import { FC, PropsWithChildren, useContext } from "react";
import { Flex, useMediaQuery } from "@chakra-ui/react";

import { useTheme } from "@chakra-ui/react";
import { NavigationContext } from "./Navigation/NavigationContext";

export const ContentContainer: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const { isSideBarOpen, toggleSideBar } = useContext(NavigationContext);
  const [isLargerThan860] = useMediaQuery("(min-width: 860px)");

  const mobilePadding = !isLargerThan860 ? 0 : "70px";
  return (
    <Flex
      flex="1"
      bg={theme.colors.backgrounds.secondary}
      overflow="scroll"
      paddingLeft={isSideBarOpen && isLargerThan860 ? "225px" : mobilePadding}
      transition="0.3s ease"
    >
      {children}
    </Flex>
  );
};
