import React, { FC, useEffect, useRef, useState } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@chakra-ui/react";
import Cropper from "react-cropper";
import { MdOutlineRotateLeft, MdOutlineCancel } from "react-icons/md";
import { FaSave } from "react-icons/fa";

import "./styles.css";
import { AssetType } from "..";
import { useTranslation } from "react-i18next";
import { useObserveElementWidth } from "./useHooks";

type ModifingAssetProp = {
  imageWidth: number;
  imageHeight: number;
  aspectRatioWidth: number;
  aspectRatioHeight: number;
  onAcceptModification: (croppedAsset: AssetType) => void;
  onCancelModification: () => void;
  onWarrningChange: (warining: boolean) => void;
  file: File;
};

export const CropAsset: FC<ModifingAssetProp> = ({
  aspectRatioWidth,
  aspectRatioHeight,
  imageWidth,
  imageHeight,
  onAcceptModification,
  onCancelModification,
  onWarrningChange,
  file,
}) => {
  const [showImageSizeWarning, setShowImageSizeWarning] = useState(false);
  const { width, ref } = useObserveElementWidth<HTMLDivElement>();
  const [isLargerThan576] = useMediaQuery("(min-width: 576px)");

  const cropperRef = useRef<HTMLImageElement>(null);
  const theme = useTheme();
  const desiredAspectRatio = aspectRatioWidth / aspectRatioHeight;
  const { t } = useTranslation("common");

  useEffect(() => {
    var url = URL.createObjectURL(file);
    var img = new Image();
    img.onload = async () => {
      if (img.width < imageWidth || img.height < imageHeight) {
        setShowImageSizeWarning(true);
        console.log("Denisa onWarrningChange", true);
        onWarrningChange(true);
      }
    };

    img.src = url;
  }, [file]);

  const handleAcceptImageModifications = () => {
    onWarrningChange(false);

    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const croppedImgData = cropper.getCroppedCanvas();

    croppedImgData.toBlob((blob: File) => {
      onAcceptModification({
        mimeType: "image",
        file: blob as File,
      });
    });
  };

  const handleRotateImage = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.rotate(-90);
  };

  const handleZoomInImage = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.zoom(0.1);
  };

  const handleZoomOutImage = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.zoom(-0.1);
  };

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box width="100%" display="contents" ref={ref} position="relative">
        <Cropper
          className="cropper"
          src={URL.createObjectURL(file)}
          aspectRatio={desiredAspectRatio}
          style={{
            width: "100%",
            height: "100%",
          }}
          cropBoxResizable={false}
          guides={false}
          ref={cropperRef}
          viewMode={2}
          dragMode="none"
          autoCropArea={1}
        />
        <Box
          position="absolute"
          left="4"
          bottom={showImageSizeWarning && !isLargerThan576 ? "10" : "4"}
        >
          <Button
            padding="4"
            backgroundColor={theme.colors.backgrounds.primary}
            color={theme.colors.text.primary}
            variant="ghost"
            fontSize="24px"
            mr="4"
            width="8"
            height="8"
            onClick={handleZoomOutImage}
          >
            -
          </Button>
          <Button
            padding="4"
            backgroundColor={theme.colors.backgrounds.primary}
            color={theme.colors.text.primary}
            variant="ghost"
            fontSize="24px"
            width="8"
            height="8"
            onClick={handleZoomInImage}
          >
            +
          </Button>
        </Box>
        <Button
          position="absolute"
          right="4"
          bottom={showImageSizeWarning && !isLargerThan576 ? "10" : "4"}
          fontSize="32px"
          backgroundColor={theme.colors.backgrounds.primary}
          color={theme.colors.text.primary}
          variant="ghost"
          onClick={handleRotateImage}
        >
          <MdOutlineRotateLeft />
        </Button>
        <Box position="absolute" right="4" top="4">
          <Button
            backgroundColor={theme.colors.backgrounds.primary}
            color={theme.colors.text.primary}
            variant="ghost"
            fontSize="24px"
            mr="4"
            onClick={handleAcceptImageModifications}
          >
            <FaSave />
          </Button>
          <Button
            backgroundColor={theme.colors.backgrounds.primary}
            color={theme.colors.text.primary}
            variant="ghost"
            fontSize="28px"
            onClick={onCancelModification}
          >
            <MdOutlineCancel />
          </Button>
        </Box>
        {showImageSizeWarning && (
          <Box
            style={{
              color: "#fe8c00",
              lineHeight: "16px",
              fontSize: "14px",
              marginTop: "4px",
              height: "32px",
            }}
          >
            {t("uploadAsset.lowQualityImageWarning", {
              imageWidth,
              imageHeight,
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
