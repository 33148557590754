/******************************************
 * metadata using MTB-API
 * 05/04/2022 - Noël Thoelen - v1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import { ErrorCode, ICountryType, InSufficientTenantRights } from "..";
import {
  EnumLanguages,
  IContactCategory,
} from "../entities/DTO-models/subTypes";
import { ICardNavigationCategory } from "../entities/DTO-models/subTypes/ICardNavigationCategory";

export class MetaDataService {
  /***************************************
   * getCountries
   * endpoint : metadata/countries
   *
   * Returns
   *  list with all countries
   */
  public async getCountries(
    language: EnumLanguages,
    searchVal?: string
  ): Promise<ICountryType[]> {
    try {
      let url = `/metadata/countries?language=${language}`;
      if (searchVal) {
        url = `${url}&filter=${searchVal}`;
      }
      const json: AxiosResponse = await mtbService.getProtected(url);
      const countriesInfo = json.data as ICountryType[];
      return countriesInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description Guest app method to get all categories of infocards to fill the navigation bar
   * @link : ../api/config/InfocardNavigationCategories
   * @see http://localhost:3001/docs/#/Configuration/GetNavigationCardCategories
   *
   * @Returns array of ICardNavigationCategory
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | a token needs to be provided
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getInfocardNavigationCategories(): Promise<
    ICardNavigationCategory[]
  > {
    try {
      let url = `/config/InfocardNavigationCategories`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const categoriesInfo = json.data as ICardNavigationCategory[];
      return categoriesInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        default:
          break;
      }
      throw errResponse;
    }
  }

  public async getInfocardTags(
    lang?: EnumLanguages
  ): Promise<{ [key: string]: string[] }> {
    try {
      let url: string;
      if (lang) {
        url = `/config/Tags?language=${lang}`;
      } else {
        url = `/config/Tags`;
      }
      const json: AxiosResponse = await mtbService.getProtected(url);
      const tags = json.data as { [key: string]: string[] };
      return tags;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /******************
   * @description get all active values for the contact category field
   * @link ../api/config/concactCategories
   * @see http://localhost:3001/docs/#/Configuration/GetContactCategories
   *
   * @returns an array of IContactCategory
   * #### Errors
   * - **InSufficientTenantRights** | Occurs when the user is not logged in
   * - **General error** | A error happened on the server
   */
  public async getContactCategories(): Promise<IContactCategory[]> {
    try {
      let url = `/config/ContactCategories`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const categoriesInfo = json.data as IContactCategory[];
      return categoriesInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.NoTokenSpecified:
          throw new InSufficientTenantRights();
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
