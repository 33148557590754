import { Box } from "@chakra-ui/layout";
import { useTheme } from "@chakra-ui/system";
import React, { FC } from "react";
import { NavLink } from "../NavLink/NavLink";
import { NavLinkType } from "../NavLink/types";
import { NavLinkGroupType } from "./types";

type NavLinkGroupProps = {
  navLinkGroup: NavLinkGroupType;
};

export const NavLinkGroup: FC<NavLinkGroupProps> = ({ navLinkGroup }) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" py="15">
      {navLinkGroup.navLinkItems.map((navLink: NavLinkType) => (
        <NavLink navLink={navLink} key={navLink.name} />
      ))}
    </Box>
  );
};
