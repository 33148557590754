import React from 'react';
import { useTheme } from "@chakra-ui/react";

const SelectIcon = () => {
  const theme = useTheme();
  return (
    <svg width="12" height="12" viewBox="0 0 10 5" fill="" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00587 7.57826e-05C8.89197 0.00332671 8.78477 0.0488398 8.70604 0.128488L4.72108 3.99546L0.736123 0.128488C0.654883 0.0480271 0.544329 0.00251398 0.427913 0.00251398C0.253707 0.00251398 0.0970892 0.105731 0.0317619 0.262588C-0.0344027 0.419445 0.00496099 0.599059 0.129753 0.716905L4.4179 4.87809C4.5854 5.04064 4.85676 5.04064 5.02427 4.87809L9.31241 0.716905C9.43971 0.598246 9.47992 0.415382 9.41124 0.256899C9.3434 0.0984162 9.18259 -0.00317514 9.00587 7.57826e-05Z"
        // fill={theme.font.colors.accent}
      />
    </svg>
  );
};

export default SelectIcon;
