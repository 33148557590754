import { EnumUserRole } from "../index";

/********************
 * rolesCheck -> make sure we respect the hierarchy in roles
 * Ex - user has TENANTADMIN -> He is also Contributor
 */
export const rolesCheck = (
  userRole: EnumUserRole | null,
  rolesToCheck: EnumUserRole[]
): boolean => {
  const verifiedUserRole = userRole || EnumUserRole.PUBLIC;
  const roleHierarchy = [
    EnumUserRole.PUBLIC,
    EnumUserRole.GUEST,
    EnumUserRole.TOURGUIDE,
    EnumUserRole.OWNER,
    EnumUserRole.TENANTCONTRIBUTOR,
    EnumUserRole.TENANTADMIN,
    EnumUserRole.ADMIN,
    EnumUserRole.M2MAPI,
  ];

  let accessAllowed = false;
  rolesToCheck.forEach((role) => {
    if (
      roleHierarchy.indexOf(verifiedUserRole) >= roleHierarchy.indexOf(role)
    ) {
      accessAllowed = true;
    }
  });
  return accessAllowed;
};
