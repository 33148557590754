import _ from "lodash";
import React, { FC, PropsWithChildren, useState } from "react";
import { AlertsAPIContext } from "./AlertsContext";
import { AlertNotificationType, AlertNotificationWithIdType } from "./types";

export const AlertsAPIProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState<(AlertNotificationWithIdType | null)[]>(
    []
  );

  const removeAlert = (alertId: string) => {
    setAlerts((alerts) =>
      alerts.filter(
        (item: AlertNotificationWithIdType | null) => item?.id !== alertId
      )
    );
  };

  const addAlert = (alert: AlertNotificationType) => {
    setAlerts((alerts) => [...alerts, { id: _.uniqueId("alert_"), ...alert }]);
  };

  const contextValue = {
    alerts,
    addAlert,
    removeAlert,
  };

  return (
    <AlertsAPIContext.Provider value={contextValue}>
      {children}
    </AlertsAPIContext.Provider>
  );
};
