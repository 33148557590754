export enum EnumUserRole {
  ADMIN = "ADMIN", // MTB Administrator role
  TENANTADMIN = "TADMIN", // Tenant admin role
  TENANTCONTRIBUTOR = "TCONT", // Tenant contributor
  OWNER = "OWNER", // House Owner
  GUEST = "GUEST", // Known guest role
  PUBLIC = "PUBLIC", // Public guest accessing one of the tenants
  TOURGUIDE = "TOURGUIDE", // Tourguide within a tenant
  M2MAPI = "M2MAPI", // External API's (ex auth0)
}
