import { Auth0Provider, AppState } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

type auth0ProviderProps = {
  children: React.ReactNode;
};

export const Auth0ProviderWithNavigate: React.FC<auth0ProviderProps> = ({
  children,
}) => {
  console.log(
    "render auth0Provider",
    process.env.NX_REACT_APP_AUTH0_ADMIN_REDIRECTURI,
    process.env.NX_REACT_APP_URL
  );
  const navigate = useNavigate();
  const domain = process.env.NX_REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.NX_REACT_APP_AUTH0_ADMIN_CLIENTID;
  const redirectURI = process.env.NX_REACT_APP_AUTH0_ADMIN_REDIRECTURI;
  const audience = process.env.NX_REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience)) {
    return null;
  }
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectURI,
        scope: "openid profile email",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
