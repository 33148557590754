import { Step, Stepper } from "@mtb/mtb-ui";
import { Box } from "@chakra-ui/react";
import { useState } from "react";

export const TestStepper = () => {
  const [currentStep, setCurrentStep] = useState(2);
  function stepClickHandler(stepNbr: number) {
    console.log("step clicked");
    setCurrentStep(stepNbr);
  }
  return (
    <Box border="1px" w="100%">
      <Stepper currentStep={currentStep} onStepClick={stepClickHandler}>
        <Step title="STAP 1" subTitle="aaaaa" stepState="done">
          Step 1
        </Step>
        <Step title="STAP 2" subTitle="bbbbb" stepState="todo">
          Step 2
        </Step>
        <Step title="STAP 3" subTitle="cccccc" stepState="todo">
          Step 3
        </Step>
        <Step title="STAP 4" subTitle="Ddddddd" stepState="error">
          Step 4
        </Step>
        <div>bla</div>
      </Stepper>
    </Box>
  );
};
