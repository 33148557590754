import {
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Box,
  useMediaQuery,
  IconButton,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "./SearchIcon";
import React, { FC, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { CloseIcon } from "@chakra-ui/icons";

export type SearchBoxProps = {
  triggerSearch(value: string): void;
  searchValue: string;
  placeholder?: string;
};

/******************
 * SearchBox component
 *
 * Can be reused on every form/page where search is needed
 * Props:
 *  triggerSearch(value) -> this function is called whe the user entered a value
 *  searchValue -> keep the searchvalue between different renders or initialize it
 *  size? : lg md sm xs
 *  width?: number of pixels the search box can be wide
 *  placeholder: placeholder to show
 */
export const SearchBox: FC<SearchBoxProps> = ({
  triggerSearch,
  searchValue,
  placeholder,
}) => {
  const [expanded, setExpanded] = useState(Boolean(searchValue));
  const [searchVal, setSearchVal] = useState(searchValue);
  const [isSmallMediaScreen] = useMediaQuery("(max-width: 1100px)");
  const [isVerySmallMediaScreen] = useMediaQuery("(max-width: 547px)");
  const theme = useTheme();

  const buttonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (expanded || searchVal) {
      triggerSearch(searchVal);
      if (!searchVal) {
        setExpanded(false);
      }
    } else {
      setExpanded(true);
    }
  };

  //handle input value change
  const handleSearchValChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchVal(event.target.value);
  };

  // Handle input key down events
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      triggerSearch(searchVal);
    }
  };

  // Handle clearing the input
  const handleClearInput = () => {
    setSearchVal("");
    triggerSearch("");
    setExpanded(false);
  };

  return (
    <Box height="32px" width={isVerySmallMediaScreen ? "100%" : "200px"}>
      <InputGroup
        width={isSmallMediaScreen ? "100%" : "200px"}
        mt={isSmallMediaScreen ? "2" : "0"}
        display="flex"
      >
        {(expanded || isSmallMediaScreen) && (
          <Input
            borderRadius="2xl"
            placeholder={placeholder}
            focusBorderColor={theme.colors.text.secondary}
            autoFocus
            value={searchVal}
            onChange={handleSearchValChange}
            onKeyDown={handleKeyDown}
            height="8"
          />
        )}
        <InputRightElement
          mr="1%"
          height="32px"
          width={searchVal ? "68px" : "48px"}
        >
          <Box display="flex" flexDirection="row">
            {searchVal && (
              <IconButton
                display="flex"
                aria-label="Clear search"
                icon={<CloseIcon />}
                size="sm"
                onClick={handleClearInput}
                variant="unstyled"
                minWidth="16px"
                h="16px"
                mr="4px"
                opacity="0.4"
                fontWeight="bold"
              />
            )}
            <Button
              h="16px"
              borderRadius="lg"
              variant="unstyled"
              onClick={buttonClicked}
              value={searchVal}
              padding="0"
              minWidth="16px"
              display="flex"
            >
              <SearchIcon />
            </Button>
          </Box>
        </InputRightElement>
        {/* <InputRightElement height="32px">
            
          </InputRightElement> */}
      </InputGroup>
    </Box>
  );
};
