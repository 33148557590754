/* functions to maintain localstorage */

const LOGINID = "loginId"; // userId of user who did login
const LOGINEMAIL = "email"; // email address -> in case rememberMe is set in loginform
const LASTUSEDTENANT = "lastusedtenant"; // Last used tenant

export const saveLoginToLocalStorage = (loginId: string) => {
  localStorage.setItem(LOGINID, loginId.toString());
};

export const getLoginFromLocalStorage = () => {
  return localStorage.getItem(LOGINID);
};

export const resetLoginFromLocalStorage = () => {
  localStorage.removeItem(LOGINID);
};

export const saveLoginEmailToLocalStorage = (email: string | null) => {
  if (email) {
    localStorage.setItem(LOGINEMAIL, email);
  } else {
    localStorage.removeItem(LOGINEMAIL);
  }
};

export const getLoginEmailFromLocalStorage = () => {
  return localStorage.getItem(LOGINEMAIL);
};

export const setLocalStorageItem = (key: string, value: string | null) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export const getLocalStorageItem = (key: string): string | null => {
  return localStorage.getItem(key);
};
