import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, FormControl, FormLabel, Input, useTheme } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";

import { Tabs, TabType } from "@mtb/mtb-ui";
import { CheckCircleIcon } from "@chakra-ui/icons";

type ContentType = {
  title: string;
  tags: string;
  language: string;
};

type FormValues = {
  content: ContentType[];
};

export const LanguageTestPage = () => {
  const { i18n, t } = useTranslation("common");
  const theme = useTheme();

  const [tabIndex, setTabIndex] = useState(0);
  const [tabs, setTabs] = useState<TabType[]>([]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const languages =
    (i18n &&
      i18n.options &&
      i18n.options.supportedLngs &&
      i18n.options.supportedLngs.filter((lng: any) => lng !== "cimode")) ||
    [];

  const contentDefaultValues = languages?.map((language: string) => ({
    title: "",
    tags: "",
    icon: "",
    language: language,
  }));

  const { register, control } = useForm<FormValues>({
    defaultValues: {
      content: contentDefaultValues,
    },
    mode: "onBlur",
  });

  const updatedData: any = useWatch({
    control,
  });

  useEffect(() => {
    updatedData &&
      setTabs(
        updatedData.content?.map((data: any) => ({
          label: getLanguageLabel(data.language),
          icon:
            data.tags !== "" && data.title !== "" ? (
              <CheckCircleIcon
                ml={2}
                w={4}
                h={4}
                color={theme.colors.primary}
              />
            ) : null,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);

  const getLanguageLabel = (lng: string) =>
    lng === "en"
      ? t("languages.english")
      : lng === "nl"
      ? t("languages.dutch")
      : lng === "es"
      ? t("languages.spanish")
      : lng === "de"
      ? t("languages.german")
      : lng === "fr"
      ? t("languages.french")
      : "";

  return (
    <Box width="700px" p="30">
      <Tabs
        title="Algemene info"
        currentTab={tabIndex}
        handleTabsChange={handleTabsChange}
        tabs={tabs}
      >
        <FormControl mb="4">
          <FormLabel htmlFor="title">Title</FormLabel>
          <Input
            key={`content.${tabIndex}.title`}
            {...register(`content.${tabIndex}.title` as const)}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel htmlFor="tags">Tags</FormLabel>
          <Input
            key={`content.${tabIndex}.tags`}
            {...register(`content.${tabIndex}.tags` as const)}
          />
        </FormControl>
      </Tabs>
    </Box>
  );
};
