import React, { FC, useContext } from "react";

import {
  Avatar,
  Button,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Center,
  useTheme,
} from "@chakra-ui/react";
import { TenantContext, UserContext } from "@mtb/mtb-core";
import { useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

export const AvatarMenu: FC = () => {
  const { user, setUser } = useContext(UserContext);
  const [isLargerThan640] = useMediaQuery("(min-width: 640px)");
  const theme = useTheme();
  const { tenant } = useContext(TenantContext);
  const { loginWithPopup, logout } = useAuth0();
  const onLoginClick = async () => {
    await loginWithPopup({
      authorizationParams: {
        prompt: "login",
        tenant: tenant?.tenantKey,
      },
    });
  };

  const handleLogout = async () => {
    await logout();
    setUser(null);
  };
  let name = `${user?.firstName || ""} ${user?.lastName || ""}`.trim();
  if (!name) {
    name = "profile";
  }
  console.log("avatar", name);

  return (
    <Menu>
      {user ? (
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"link"}
          cursor={"pointer"}
          minW={0}
          display="flex"
        >
          <Box display="flex">
            <Avatar
              size={"md"}
              backgroundColor="gray.100"
              src={user?.photoUrl}
            />
            {isLargerThan640 && (
              <Box mt="3" ml="2px">
                {name}
              </Box>
            )}
          </Box>
        </MenuButton>
      ) : (
        <Button
          onClick={onLoginClick}
          variant="link"
          color={theme.colors.primary}
        >
          Login
        </Button>
      )}
      {user && (
        <MenuList alignItems={"center"} bg={theme.colors.backgrounds.primary}>
          <>
            <Center>
              <Avatar
                size={"2xl"}
                backgroundColor="gray.100"
                src={user.photoUrl}
              />
            </Center>
            {name && (
              <Center>
                <p color={theme.colors.secondary}>{name}</p>
              </Center>
            )}
            <MenuDivider />
            <MenuItem color={theme.colors.secondary} onClick={handleLogout}>
              Logout
            </MenuItem>
          </>
        </MenuList>
      )}
    </Menu>
  );
};
