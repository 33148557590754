import { FC, useContext } from "react";
import { Box, Flex, Link } from "@chakra-ui/layout";
import { NavLink as ReactLink, useMatch } from "react-router-dom";
import { NavLinkType } from "./types";

import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { NavigationContext } from "../NavigationContext";
import { useTheme } from "@chakra-ui/system";
import { IconContainer, StyledLink } from "./styles";

type NavLinkProps = {
  navLink: NavLinkType;
};

export const NavLink: FC<NavLinkProps> = ({
  navLink: { name, icon, path, component, noHoverStyling },
}) => {
  const { isSideBarOpen } = useContext(NavigationContext);
  const theme = useTheme();
  const isActive = !!useMatch(path || "");

  return (
    <StyledLink
      as={ReactLink}
      to={path || ""}
      display="flex"
      fontSize="sm"
      rounded={"md"}
      alignSelf="center"
      position="relative"
      width="100%"
      whiteSpace="nowrap"
    >
      <Flex alignItems="center" h="48px" marginBottom="8px" position="relative">
        <IconContainer isActive={isActive} className="icon-container">
          {icon}
        </IconContainer>
        <Box
          color={theme.colors.text.onSecondary}
          position="absolute"
          width={isSideBarOpen ? "auto" : 0}
          transform={isSideBarOpen ? "translateX(0)" : "translateX(8px)"}
          transition=".3s"
          left="68px"
        >
          {name}
        </Box>
        {component}
      </Flex>
    </StyledLink>
  );
};
