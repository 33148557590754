import React, { FC } from "react";
import { Box, Button, Image, useTheme } from "@chakra-ui/react";
import { MdOutlineCancel } from "react-icons/md";

import { AssetType } from "../Asset/types";
import { Asset } from "../Asset";

type DisplayAssetProps = {
  /** The asset that needs to be displaied.  */
  asset: AssetType;
  /** Function to be called in case the user cancel the selected asset. */
  onCancelAsset: () => void;
};
/** Display Asset component will take care of the third step of AssetEditor component and that is displaing the chosen image/video.
 * This component will offer also the posibility to cancel the asset and in case the user chooses this it will call onCancelAsset to notify the parent component.
 */
export const DisplayAsset: FC<DisplayAssetProps> = ({
  asset,
  onCancelAsset,
}) => {
  const theme = useTheme();
  const assetSource = asset.url
    ? asset.url
    : asset.file
    ? URL.createObjectURL(asset.file)
    : "";

  return (
    <Box
      position="relative"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Asset
        asset={{
          mimeType: asset.mimeType,
          url: assetSource,
        }}
        activateVideoControls={true}
      />

      <Box position="absolute" top="12px" right="12px">
        <Button
          variant="ghost"
          backgroundColor={theme.colors.backgrounds.terciary}
          fontSize="28px"
          onClick={onCancelAsset}
        >
          <MdOutlineCancel />
        </Button>
      </Box>
    </Box>
  );
};
