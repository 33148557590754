import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import Flag from "react-world-flags";

import { OptionType, Select } from "@mtb/mtb-ui";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const dropdownOptions: any = [];

  i18n &&
    i18n.options &&
    i18n.options.supportedLngs &&
    i18n.options.supportedLngs.forEach((lng) => {
      lng !== "cimode" &&
        dropdownOptions.push({
          value: lng,
          label: (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box mr="0.5rem">
                <Flag
                  code={lng === "en" ? "US" : lng}
                  style={{
                    height: "0.9em",
                    alignItems: "justify",
                    width: "18px",
                  }}
                />
              </Box>

              {lng.toUpperCase()}
            </Box>
          ),
        });
    });

  const changeLanguage = (option: OptionType) => {
    i18n.changeLanguage(option.value);
  };

  return (
    <>
      <Select
        styles={{
          width: "50px",
        }}
        name="language-switcher"
        options={dropdownOptions}
        onChange={changeLanguage}
        isSearchable={false}
        value={{
          value: i18n.language,
          label: (
            <span>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box mr="0.5rem">
                  <Flag
                    code={i18n.language === "en" ? "US" : i18n.language}
                    style={{
                      height: "0.9em",
                      alignItems: "justify",
                      width: "18px",
                    }}
                  />
                </Box>
                {i18n.language.toUpperCase()}
              </Box>
            </span>
          ),
        }}
      />
    </>
  );
};
