import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Progress,
  useTheme,
} from "@chakra-ui/react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation, Trans } from "react-i18next";
import { BsImage } from "react-icons/bs";

import { AssetType } from "../Asset/types";
import { useObserveElementWidth } from "./useHooks";
import imageCompression from "browser-image-compression";

type SelectAssetProps = {
  /** Function to be called with the selected asset from the user, after the selection is done. */
  onAssetSelection: (asset: AssetType) => void;
  /** Error state */
  errorState?: boolean;
};

/** Select Asset component will get the asset from the user and will call onAssetSelection (with the asset as a prop) when the user has selected an asset.
 * The asset selection can be done through: browsing in the computer for an image/video, drag & drop image/video OR embed video URL
 */
export const SelectAsset: FC<SelectAssetProps> = ({
  errorState,
  onAssetSelection,
}) => {
  const { width, ref } = useObserveElementWidth<HTMLDivElement>();
  const { t } = useTranslation("common");

  const [url, setUrl] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const theme = useTheme();
  const fileTypes = [
    "JPEG",
    "JPG",
    "PNG",
    "GIF",
    "SVG",
    "AVIF",
    "WebP",
    "APNG",
    "mp4",
  ];

  const handleFileUploaderChange = async (file: File) => {
    if (file.type.includes("image")) {
      setIsUploading(true);
      setUploadProgress(0); // Start progress

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2, // Max size (in MB) for the compressed image
        maxWidthOrHeight: 1920, // Resize to fit within this dimension
        useWebWorker: true,
        onProgress: (percent) => {
          setUploadProgress(percent); // Update progress (0-100%)
        },
      });

      setUploadProgress(100);
      setTimeout(() => {
        onAssetSelection({
          mimeType: "image",
          file: compressedFile,
        });
        setIsUploading(false);
        setUploadProgress(0);
      }, 500); // Ensure the full progress bar displays before reset
    } else {
      onAssetSelection({
        mimeType: "video",
        file,
      });
    }
  };

  const handleApplyEmbeddedUrl = () => {
    url &&
      onAssetSelection({
        mimeType: "video",
        url,
      });
  };

  const handleChangeEmbeddedUrl = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(target.value);
  };

  return (
    <Box
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
        display: "contents",
      }}
      sx={{
        ".file-uploader": {
          position: "absolute",
          width: "100%",
          boxSizing: "border-box",
          height: "100%",
          border: `1px dashed ${errorState ? "red" : "#07baa0"}`,
          borderRadius: "4px",
          backgroundColor: "#07baa01a",
          ":hover": {
            cursor: "pointer",
          },
        },
      }}
    >
      <FileUploader
        name="file"
        handleChange={handleFileUploaderChange}
        types={fileTypes}
        classes="file-uploader"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          lineHeight={width > 200 ? "auto" : "0.75rem"}
          fontSize={width > 200 ? "16px" : "0.75rem"}
        >
          <Box mb="2">
            <BsImage size="28" color={theme.colors.primary} />
          </Box>
          <Trans
            ns="common"
            i18nKey="uploadAsset.placeholder"
            components={{
              underline: (
                <Text
                  textDecoration="underline"
                  _hover={{ cursor: "pointer" }}
                />
              ),
            }}
          />
        </Box>
      </FileUploader>

      {isUploading && (
        <Progress
          value={uploadProgress}
          size="xs"
          sx={{
            "& > div": {
              backgroundColor: theme.colors.primary,
            },
          }}
          mt={4}
          width="100%"
        />
      )}

      <Box position="absolute" bottom="4" left="4" right="4" width="auto">
        <InputGroup size={width > 200 ? "md" : "sm"}>
          <Input
            onChange={handleChangeEmbeddedUrl}
            pr={width > 200 ? "4.5rem" : "3rem"}
            placeholder={t("uploadAsset.enterURL")}
            width="100%"
            aria-invalid={false}
          />

          <InputRightElement
            width={width > 200 ? "4.5rem" : "3rem"}
            mr={width > 200 ? "1.5" : "0.7"}
          >
            <Button
              size="sm"
              p={width > 200 ? "4" : "2"}
              height={width > 200 ? "1.75rem" : "1.55rem"}
              onClick={handleApplyEmbeddedUrl}
            >
              {t("uploadAsset.applyURL")}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
    </Box>
  );
};
