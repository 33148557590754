import { IAssetType } from "../entities/DTO-models/subTypes";

export const appendIAssetTypeData = (
  theFormData: FormData,
  theAsset?: IAssetType,
  propertyName: string = "image"
): void => {
  if (!theAsset) return;
  // image data is unchanged, pass the old values
  if (theAsset.id) {
    theFormData.append(`${propertyName}[id]`, theAsset.id.toString());
    return;
  }
  if (theAsset.file) {
    // new image has been uploaded, pass it
    theFormData.append(`${propertyName}[file]`, theAsset.file);
    theAsset.mimeType &&
      theFormData.append(`${propertyName}[mimeType]`, theAsset.mimeType);
    return;
  }
  if (theAsset.url) {
    // an embed image/video is uploaded
    theFormData.append(`${propertyName}[url]`, theAsset.url);
    theAsset.mimeType &&
      theFormData.append(`${propertyName}[mimeType]`, theAsset.mimeType);
    return;
  }
};
