export const getLanguageValueFromMLField = (
  mlField: { [key: string]: string },
  lng: string,
  fallBackLng: string
): string => {
  if (lng in mlField) {
    return mlField[lng];
  } else {
    return mlField[fallBackLng];
  }
};
