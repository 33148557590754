// import { AreaEdit, DestinationEdit } from "@mtb/mtb-core";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute/protectedRoute";
import { tenantRolePermissions } from "./constants";
import { AreaProvider } from "../context/areaContext/AreaProvider";
import { DestinationProvider } from "../context/destinationContext/DestinationProvider";
const TenantBasePage = React.lazy(() =>
  import("../pages/TenantBase").then((module) => ({
    default: module.TenantBasePage,
  }))
);

const AreaOverviewPage = React.lazy(() =>
  import("../pages/Area").then((module) => ({
    default: module.AreaPage,
  }))
);
const DestinationOverviewPage = React.lazy(() =>
  import("../pages/Destination").then((module) => ({
    default: module.DestiantionPage,
  }))
);

export const TenantRoutes = React.memo(() => {
  const location = useLocation();
  const locationState = location.state as { backgroundLocation?: Location };

  return (
    <React.Fragment>
      <Routes location={locationState?.backgroundLocation || location}>
        <Route
          path="/"
          element={
            <ProtectedRoute
              validRoles={tenantRolePermissions}
              element={<TenantBasePage />}
            />
          }
        />
        <Route
          path="/areas/:areaId/"
          element={
            <ProtectedRoute
              validRoles={tenantRolePermissions}
              element={
                <AreaProvider>
                  <DestinationProvider>
                    <AreaOverviewPage />
                  </DestinationProvider>
                </AreaProvider>
              }
            />
          }
        />
        <Route
          path="/areas/:areaId/destinations/:destinationId"
          element={
            <ProtectedRoute
              validRoles={tenantRolePermissions}
              element={
                <AreaProvider>
                  <DestinationProvider>
                    <DestinationOverviewPage />
                  </DestinationProvider>
                </AreaProvider>
              }
            />
          }
        />
      </Routes>

      {/* show the modal when a backgroundlocation is set */}
      {locationState?.backgroundLocation && (
        <Routes>
          {/** Tenant info cards edit and add */}
          {/** GENERAL INFO CARDS */}

          {/* <Route
            path="/infoCard/:infoCardId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<InfoCardEdit />}
              />
            }
          />
          <Route
            path="/infoCard/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<InfoCardEdit />}
              />
            }
          /> */}

          {/** CONTACTS INFO CARDS */}

          {/* <Route
            path="/contactCard/:contactCardId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<ContactCardEdit />}
              />
            }
          /> */}
          {/* <Route
            path="/contactCard/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<ContactCardEdit />}
              />
            }
          /> */}

          {/** Area edit and add */}
          {/* <Route
            path="/area/:areaId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<AreaEdit />}
              />
            }
          />
          <Route
            path="/area/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<AreaEdit />}
              />
            }
          /> */}

          {/** Area info cards edit and add */}
          {/* <Route
            path="/areas/:areaId/infoCard/:infoCardId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<InfoCardEdit />}
              />
            }
          />
          <Route
            path="/areas/:areaId/infoCard/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<InfoCardEdit />}
              />
            }
          /> */}

          {/** Area contact info cards edit and add */}
          {/* <Route
            path="/areas/:areaId/contactCard/:contactCardId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<ContactCardEdit />}
              />
            }
          />
          <Route
            path="/areas/:areaId/contactCard/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<ContactCardEdit />}
              />
            }
          /> */}

          {/** Destination edit and add */}
          {/* <Route
            path="/areas/:areaId/destination/:destinationId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<DestinationEdit />}
              />
            }
          />
          <Route
            path="/areas/:areaId/destination/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<DestinationEdit />}
              />
            }
          /> */}

          {/** Destination info cards edit and add */}
          {/* <Route
            path="/areas/:areaId/destinations/:destinationId/infoCard/:infoCardId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<InfoCardEdit />}
              />
            }
          />
          <Route
            path="/areas/:areaId/destinations/:destinationId/infoCard/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<InfoCardEdit />}
              />
            }
          /> */}

          {/** Destination contact info cards edit and add */}
          {/* <Route
            path="/areas/:areaId/destinations/:destinationId/contactCard/:contactCardId"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<ContactCardEdit />}
              />
            }
          />
          <Route
            path="/areas/:areaId/destinations/:destinationId/contactCard/add"
            element={
              <ProtectedRoute
                validRoles={tenantRolePermissions}
                element={<ContactCardEdit />}
              />
            }
          /> */}
        </Routes>
      )}
    </React.Fragment>
  );
});
