export const LibImageSizes = {
  infocard: {
    image: { large: 1200, medium: 600, small: 300, ratioH: 9, ratioW: 16 },
    asset: { large: 1200, medium: 600, small: 300, ratioH: 9, ratioW: 16 },
  },
  area: {
    image: { large: 1200, medium: 600, small: 300, ratioH: 9, ratioW: 16 },
  },
  destination: {
    image: { large: 1200, medium: 600, small: 300, ratioH: 9, ratioW: 16 },
  },
};
