import React, { FC, useContext, useEffect } from "react";

import {
  Alert as AlertComponent,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
} from "@chakra-ui/react";
import { AlertNotificationWithIdType } from "@mtb/mtb-core";
import { AlertsAPIContext } from "../AlertsContext";

export const AlertNotification: FC<AlertNotificationWithIdType> = ({
  duration,
  type,
  title,
  description,
  id,
}) => {
  const { removeAlert } = useContext(AlertsAPIContext);

  const handleRemoveAlert = () => removeAlert(id);
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        handleRemoveAlert();
        return () => clearTimeout(timer);
      }, duration);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <AlertComponent status={type} m="3" variant="left-accent" width="auto">
      <AlertIcon />
      <Box flex="1">
        <AlertTitle mr={2}>{title}</AlertTitle>
        <AlertDescription mr={8}>{description}</AlertDescription>
      </Box>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={handleRemoveAlert}
      />
    </AlertComponent>
  );
};
