import { AxiosResponse } from "axios";
import { ITripType } from "../entities/DTO-models/ITripType";
import { mtbService } from "./mtbService";
import {
  ErrorCode,
  InSufficientTenantRights,
  InvalidTenantError,
  NotFoundError,
} from "../entities/Error/ErrorCodes";

export class TripService {
  public async getProgramForTrip(
    tenantKey: string,
    tripCode: string
  ): Promise<ITripType> {
    try {
      const url = `/tenant/${tenantKey}/trip/${tripCode}/program`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const tripInfo = json.data as ITripType;
      return tripInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.InvalidTripCode:
          throw new NotFoundError();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
