import { defaultMTBTheme } from "./defaultMTBTheme";
import { Theme } from "./types/theme";

// Default themes objects
export { defaultMTBTheme };
export { breakpoints, overrides } from "./defaultMTBTheme";
// types
export type { Theme };
// images to be used in admin and client app
export * from "./images";
//utils
export { getRgbaColor } from "./utils/getRgbaColor";
