/******************************************
 * tenant data using MTB-API
 * 03/08/2021 - Noël Thoelen - v1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import { IInfocardsOverviewResponse } from "../entities/DTO-models/IInfocardsOverviewResponse";
import {
  DuplicateAliasError,
  ErrorCode,
  IInfocardType,
  InfoCardHasRelatedDataError,
  InSufficientTenantRights,
  InvalidAliasChangeError,
  InvalidDataError,
  InvalidInfoCardError,
  InvalidTenantError,
  NotFoundError,
  OptimisticLockError,
} from "..";
import { appendMLToFormData } from "../helpers/appendMLToFormData";
import { appendIAssetTypeData } from "../helpers/appendIAssetTypeData";
import { IInfocardBodyBasicType } from "../entities/DTO-models/subTypes/IInfocardBodyType";

type MimeType = "image" | "video";

type VariantType = {
  url: string;
  width: number;
};

type AssetType = {
  mimeType?: MimeType;
  url?: string;
  file?: File;
  variant?: {
    small?: VariantType;
    medium?: VariantType;
    large?: VariantType;
  };
  altText?: string;
};

type InfoCardAssets = {
  assetContentIndex2: AssetType | undefined;
  assetContentIndex4: AssetType | undefined;
  assetContentIndex6: AssetType | undefined;
  assetContentIndex8: AssetType | undefined;
  assetContentIndex10: AssetType | undefined;
};

export class InfocardService {
  /***************************************
   * @description Get infocards overview for
   * - the tenant
   * - an area
   * - a specific destination
   * @link : ../api/tenant/{tenantKey}/infocard/navigationOverview
   * @see http://localhost:3001/docs/#/infocards/GetInfocardsNavigationOverview
   *
   * @param tenantKey The tenantKey
   * @param areaId Optional The area where we want the infocards overview for
   * @param destinationId Optional the destinationId where we want the overview for
   * @Returns array of IInfocardsOverviewRespsonse
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - general Error | something went wrong (probably network issues)
   */
  public async getInfocardsForTenant(
    tenantKey: string,
    areaId?: string,
    destinationId?: string
  ): Promise<IInfocardsOverviewResponse[]> {
    try {
      let url = `/tenant/${tenantKey}/infocard/navigationOverview`;
      if (areaId) {
        url = `/tenant/${tenantKey}/infocard/navigationOverview?area=${areaId}`;
      }
      if (destinationId) {
        url = `/tenant/${tenantKey}/infocard/navigationOverview?destination=${destinationId}`;
      }
      const json: AxiosResponse = await mtbService.getProtected(url);
      const tenantCards = json.data as IInfocardsOverviewResponse[];
      return tenantCards;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw new Error((errResponse as Error).message);
    }
  }

  /***************************************
   * @description Save infocard info (add/update)
   * @link : ../api/tenant/{tenantKey}/infocard/navigationOverview
   * @see http://localhost:3001/docs/#/infocards/GetInfocardsNavigationOverview
   *
   * @param tenantKey The tenantKey
   * @param areaId Optional The area where we want the infocards overview for
   * @param destinationId Optional the destinationId where we want the overview for
   * @Returns array of IInfocardsOverviewRespsonse
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * **InvalidDataError** | Invalid payload passed
   * **InvalidInfoCardError** | Infocard does not exist
   * **InSufficientTenantRights** | User has no right to add/update infocard
   * **OptimisticLockError** | Another user did change the card (version changed)
   * - general Error | something went wrong (probably network issues)
   */
  public async saveInfoCard(
    tenantKey: string,
    data: IInfocardType,
    cardId?: string,
    version?: number
  ): Promise<void> {
    try {
      let json: AxiosResponse;
      const formData = new FormData();
      data.id && formData.append("id", data.id.toString());
      data.version && formData.append("version", data.version.toString());
      formData.append("template", data.template);
      formData.append("isTenantCard", data.isTenantCard.toString());
      formData.append("isCommunityCard", data.isCommunityCard.toString());
      formData.append("sorting", data.sorting.toString());
      formData.append(
        "navigationCategory[id]",
        data.navigationCategory.id.toString()
      );
      formData.append("publicationStatus", data.publicationStatus.toString());
      appendMLToFormData("title", formData, data.title);
      appendMLToFormData("introText", formData, data.introText);
      appendIAssetTypeData(formData, data.image, "image");
      appendMLToFormData("tags", formData, data.tags);
      data.visibleForRoles?.map((role, i) => {
        formData.append(`visibleForRoles[${i}]`, role);
      });
      this._appendBodyContentToFormData("content", formData, data.content);
      data.newAssets &&
        data.newAssets.map((assetVal, assetIndex) => {
          formData.append("newAssets", assetVal);
        });
      data.areaIds &&
        data.areaIds.map((areaId, i) => {
          formData.append(`areaIds[${i}]`, areaId.toString());
        });
      data.destinationIds &&
        data.destinationIds.map((destId, i) => {
          formData.append(`destinationIds[${i}]`, destId.toString());
        });
      if (cardId) {
        const url = `/tenant/${tenantKey}/infocard/${cardId}/${version}`;
        json = await mtbService.postPutFormDataProtected("put", url, formData);
      } else {
        const url = `/tenant/${tenantKey}/infocard`;
        json = await mtbService.postPutFormDataProtected("post", url, formData);
      }
    } catch (errResponse: any) {
      const errCode: ErrorCode = errResponse.response?.data
        ?.errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.NotFound:
          throw new InvalidInfoCardError();
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        case ErrorCode.DataInconsistancy:
          throw new InvalidDataError();
        case ErrorCode.InvalidPayload:
          throw new InvalidDataError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description Guest app methoed to get infocards for a destination
   * @link : ../api/tenant/{tenantKey}/appInfo/{destinationId}/infocards
   * @see http://localhost:3001/docs/#/appInfo/GetInfocardsForDestination
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @param destinationId destinationId where the app in initalized for
   * @param category Optional infocard category to fetch cards for
   * @param filter Optional - a specific filter value to filter the infocards
   * @param cardId Optional - a specific infocard to filter on
   * @Returns array of IInfocardType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | a token needs to be provided
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getInfocardsForDestination(
    tenantKey: string,
    destinationId: number,
    category?: string,
    filter?: string,
    cardId?: number
  ): Promise<IInfocardType[]> {
    try {
      let url = new URL(
        `/tenant/${tenantKey}/appInfo/${destinationId}/appInfocards`,
        process.env.NX_REACT_APP_MTBAPI_URL
      );
      if (category) {
        url.searchParams.set("category", category);
      }
      if (filter) {
        url.searchParams.set("search", filter);
      }
      if (cardId) {
        url.searchParams.set("cardId", cardId.toString());
      }

      const json: AxiosResponse = await mtbService.getProtected(
        url.pathname + url.search
      );
      const tenantCards = json.data as IInfocardType[];
      return tenantCards;
    } catch (errResponse) {
      console.log("getinfocards error", errResponse);
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw new Error((errResponse as Error).message);
    }
  }

  /***************************************
   * @description Get a sigle infocard
   * @link : ../api/tenant/{tenantKey}/infocard/{cardId}
   * @see http://localhost:3001/docs/#/infocards/GetInfocard
   *
   * @param cardId id of the card to fetch
   *
   * @returns the infocatd JSON IInfocardType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InvalidInfoCardError | No infocard exist
   * - InSufficientTenantRights | not enough rights to fetch infocard
   * - general Error | something went wrong (probably network issues)
   */
  public async getInforCardForTenant(
    tenantKey: string,
    infoCardId?: number
  ): Promise<IInfocardType> {
    try {
      const url = `/tenant/${tenantKey}/infocard/${infoCardId}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const infoCard = json.data as IInfocardType;
      return infoCard;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.NotFound:
          throw new InvalidInfoCardError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description Delete a basic infocard with a certain Id
   * @link : ../api/tenant/{tenantKey}/infocard/{cardId}/{version}
   * @see http://localhost:3001/docs/#/infocards/DeleteInfocard
   *
   * @param tenantKey The tenantKey
   * @param cardId infocard Id to be deleted
   * @param version version of the card
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * **InvalidDataError** | Invalid payload passed
   * **NotFoundError** | Infocard does not exist
   * **InSufficientTenantRights** | User has no right to delete infocard
   * **OptimisticLockError** | Another user did change the card (version changed)
   * **InfoCardHasRelatedDataError** | The infocard still has related data
   * - general Error | something went wrong (probably network issues)
   */
  public async deleteInfoCardForTenant(
    tenantKey: string,
    infoCardId: number,
    version: number
  ) {
    try {
      // TODO you need to pass in the version as part of the url for optilistic lock checking
      const url = `/tenant/${tenantKey}/infocard/${infoCardId}/${version}`;
      const json: AxiosResponse = await mtbService.deleteProtected(url);
    } catch (errResponse: any) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        case ErrorCode.RecordHasRelatedData:
          throw new InfoCardHasRelatedDataError();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  private _appendBodyContentToFormData(
    propertyName: string,
    theFormData: FormData,
    theBodyObject?: { [lang: string]: IInfocardBodyBasicType[] }
  ) {
    if (theBodyObject) {
      Object.entries(theBodyObject).forEach(([lang, transValue]) => {
        transValue.forEach((basicVal, i) => {
          switch (basicVal.rowType) {
            case "text":
              // text - do not send empty rows
              this._appendBodyTextContentToFormData(
                `${propertyName}[${lang}][${i}]`,
                theFormData,
                basicVal.text
              );
              break;
            case "asset": {
              // asset - do not send empty assets
              this._appendBodyAssetContentToFormData(
                `${propertyName}[${lang}][${i}]`,
                theFormData,
                basicVal.asset
              );
              break;
            }
          }
        });
      });
    }
  }

  private _appendBodyTextContentToFormData = (
    fdField: string,
    theFormData: FormData,
    textValue: string | undefined | null
  ): void => {
    // text - do not send empty rows
    if (textValue && textValue !== "" && textValue !== "<p><br /></p>") {
      theFormData.append(`${fdField}[rowType]`, "text");
      theFormData.append(`${fdField}[text]`, textValue);
    }
  };
  private _appendBodyAssetContentToFormData = (
    fdField: string,
    theFormData: FormData,
    theAsset?:
      | {
          assetId?: number;
          newFileName?: string;
          newEmbedurl?: string;
        }
      | undefined
      | null
  ): void => {
    // asset - do not send empty rows
    if (
      theAsset &&
      (theAsset.assetId || theAsset.newFileName || theAsset.newEmbedurl)
    ) {
      theFormData.append(`${fdField}[rowType]`, "asset");
      theAsset.assetId &&
        theFormData.append(
          `${fdField}[asset][assetId]`,
          theAsset.assetId.toString()
        );
      theAsset?.newFileName &&
        theFormData.append(
          `${fdField}[asset][newFileName]`,
          theAsset.newFileName
        );
      theAsset?.newEmbedurl &&
        theFormData.append(
          `${fdField}[asset][newEmbedurl]`,
          theAsset.newEmbedurl
        );
    }
  };
}
