import React, { Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import { NotFound, TenantContext, UserContext } from "@mtb/mtb-core";
import { EnumUserRole } from "@mtb/mtb-requests";
import { Loader } from "@mtb/mtb-ui";

import { AssetsEditorPage } from "../pages/Demo/AssetsEditor";
import { TenantRoutes } from "./TenantRoutes";
import { TestStepper } from "../pages/Demo/testStepper";
import { LanguageTestPage } from "../pages/Demo/LanguageTest";
import { ProtectedRoute } from "./ProtectedRoute/protectedRoute";
import { adminAppRolePermissions, tenantRolePermissions } from "./constants";
import { AreaProvider } from "../context/areaContext/AreaProvider";

const AboutPage = React.lazy(() =>
  import("../pages/About").then((module) => ({
    default: module.AboutPage,
  }))
);

const TenantsPage = React.lazy(() =>
  import("../pages/TenantOverview").then((module) => ({
    default: module.TenantOverviewPage,
  }))
);

const LoginPage = React.lazy(() =>
  import("../pages/LoginPage").then((module) => ({
    default: module.LoginPage,
  }))
);

export const AppRoutes = React.memo(() => {
  const { t } = useTranslation("common");
  const { tenant } = useContext(TenantContext);
  const { user } = useContext(UserContext);
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* About page */}
        <Route path="/about" element={<AboutPage />} />

        {/* Tenant pages with sub pages */}
        <Route
          path="/tenants/:tenantKey/*"
          element={
            <ProtectedRoute
              validRoles={tenantRolePermissions}
              element={<TenantRoutes />}
            />
          }
        />

        {/* Tenants page */}
        <Route
          path="/tenants"
          element={
            <ProtectedRoute
              validRoles={adminAppRolePermissions}
              element={<TenantsPage />}
            />
          }
        />

        {/* Tenant impersonation logic and user not logged in */}
        <Route
          path="/"
          element={
            user ? (
              tenant && tenant.userRoleInTenant !== EnumUserRole.GUEST ? (
                <Navigate to={`/tenants/${tenant.tenantKey}`} />
              ) : (
                <Navigate to="/tenants" />
              )
            ) : (
              <LoginPage />
            )
          }
        />

        {/* Demo pages */}
        {process.env.NODE_ENV !== "production" && (
          <>
            <Route path="/assetsEditor" element={<AssetsEditorPage />} />
            <Route path="/contentLanguage" element={<LanguageTestPage />} />
            <Route path="/test" element={<TestStepper />} />
          </>
        )}

        {/* Fallback page */}
        <Route
          path="*"
          element={<NotFound errorMessage={t("general.404")} />}
        />
      </Routes>
    </Suspense>
  );
});
