import React, { FC, PropsWithChildren } from "react";
import {
  Box,
  Text,
  Tab,
  TabList,
  Tabs as ChakraTabs,
  useTheme,
} from "@chakra-ui/react";
import { TabType } from "./types";

type TabsProps = {
  title?: string;
  tabs: TabType[];
  defaultTab?: number;
  currentTab?: number;
  handleTabsChange?: (currentTab: number) => void;
};

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({
  title,
  tabs,
  children,
  defaultTab,
  currentTab,
  handleTabsChange,
}) => {
  const theme = useTheme();

  return (
    <Box
      border={`2px solid ${theme.colors.borders.light}`}
      borderTop={`8px solid ${theme.colors.borders.strong}`}
      borderRadius="10"
      bg={theme.colors.backgrounds.primary}
    >
      <Text p="4" fontWeight="bold">
        {title}
      </Text>
      <ChakraTabs
        index={currentTab}
        onChange={handleTabsChange}
        defaultIndex={defaultTab}
      >
        <TabList borderBottom="none">
          {tabs?.map((tab: any) => (
            <Tab
              color={theme.colors.text.quaternary}
              fontWeight="bold"
              px="0"
              mx="4"
              _selected={{
                color: "black",
                borderBottom: `3px solid black`,
              }}
              key={tab.label}
            >
              {tab.label}
              {tab.required && " *"}
              {tab.icon}
            </Tab>
          ))}
        </TabList>
        <Box p="4">{children}</Box>
      </ChakraTabs>
    </Box>
  );
};
