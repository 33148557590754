import { AlertsAPIContext, AlertNotificationWithIdType } from "@mtb/mtb-core";
import React, { useContext } from "react";

import { Box } from "@chakra-ui/react";
import { AlertNotification } from "../AlertNotification";

export const AlertsAPI = () => {
  const { alerts } = useContext(AlertsAPIContext);

  return (
    <Box position="fixed" zIndex="popover" right="2" left="2" top="2">
      {alerts.length > 0 &&
        alerts?.map(
          (alert: AlertNotificationWithIdType | null) =>
            alert && (
              <AlertNotification
                key={alert?.id}
                id={alert?.id}
                title={alert.title}
                type={alert.type}
                description={alert.description}
                duration={alert.duration}
              />
            )
        )}
    </Box>
  );
};
