import { FC, PropsWithChildren, useState } from "react";

import {
  resetLoginFromLocalStorage,
  saveLoginToLocalStorage,
} from "../localCaching/localCaching";
import { UserType } from "./types";
import { UserContext } from "./UserContext";

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUserContext] = useState<UserType | null>(null);

  const setUser = (user: UserType | null) => {
    setUserContext(user);
    if (user) {
      saveLoginToLocalStorage(user.userId);
    } else {
      resetLoginFromLocalStorage();
    }
  };

  const contextValue = {
    user,
    setUser,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
