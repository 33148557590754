import React from "react";
import { AssetEditor } from "@mtb/mtb-ui";
import { Box } from "@chakra-ui/react";

export const AssetsEditorPage = () => {
  const getAssetData = (data: any) => {};

  return (
    <Box p="16px">
      <AssetEditor
        imageWidth={400}
        imageHeight={300}
        containerHeight="300px"
        containerWidth="450px"
        aspectRatioWidth={3}
        aspectRatioHeight={9}
        onUploadAsset={getAssetData}
        // uploadedAsset={{
        //   type: "video",
        //   url: "https://youtu.be/A3yBbB-4eTU",
        // }}
        uploadedAsset={{
          mimeType: "image",
          url: "https://images.unsplash.com/photo-1631193079266-4af74b218c86?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzOTg0OTY5MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1900",
        }}
      />
    </Box>
  );
};
