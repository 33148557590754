import React, { ChangeEvent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalHeader,
  CheckboxGroup,
  Button,
  Checkbox,
  useTheme,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../../language-switcher/index";

type Cookie =
  | typeof COOKIE_CONSENTDATE
  | typeof COOKIE_NECESSARY
  | typeof COOKIE_PREFERENCE
  | typeof COOKIE_STATISTICAL
  | typeof COOKIE_ADS;

export const COOKIE_CONSENTDATE = "mtb_consentDate";
const COOKIE_NECESSARY = "mtb_NecessaryCookies";
const COOKIE_PREFERENCE = "mtb_PreferenceCookies";
const COOKIE_STATISTICAL = "mtb_StatisticalCookies";
const COOKIE_ADS = "mtb_AdCookies";

const allCookiesTypes: Cookie[] = [
  COOKIE_CONSENTDATE,
  COOKIE_NECESSARY,
  COOKIE_PREFERENCE,
  COOKIE_STATISTICAL,
  COOKIE_ADS,
];

export const CookieConsent = () => {
  const [cookies, setCookie, removeCookie] = useCookies(allCookiesTypes);
  const [selectedCookies, setSelectedCookies] = useState<Cookie[]>([]);

  const nextYear = new Date();
  nextYear.setFullYear(new Date().getFullYear() + 1);
  const consentOptions = { path: "/", expires: nextYear };

  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  useEffect(() => {
    setSelectedCookies([
      ...(Object.keys(cookies) as Cookie[]),
      COOKIE_NECESSARY,
    ]);
  }, []);

  const removeAllCookies = () => {
    Object.keys(cookies).map((cookie) => {
      removeCookie(cookie as Cookie);
    });
  };

  const concentSelected = () => {
    removeAllCookies();
    setCookie(COOKIE_CONSENTDATE, new Date(), consentOptions);

    selectedCookies.map((cookie) => {
      setCookie(cookie as Cookie, true, consentOptions);
    });
  };

  const concentAll = () => {
    removeAllCookies();
    setSelectedCookies(allCookiesTypes);

    setCookie(COOKIE_CONSENTDATE, new Date(), consentOptions);
    setCookie(COOKIE_NECESSARY, true, consentOptions);
    setCookie(COOKIE_PREFERENCE, true, consentOptions);
    setCookie(COOKIE_STATISTICAL, true, consentOptions);
    setCookie(COOKIE_ADS, true, consentOptions);
  };

  const toggleCheckboxValue = (event: ChangeEvent<HTMLInputElement>) => {
    const index = selectedCookies.indexOf(event.target.value as Cookie);
    if (index > -1) {
      setSelectedCookies([
        ...selectedCookies.slice(0, index),
        ...selectedCookies.slice(index + 1),
      ]);
    } else {
      setSelectedCookies([...selectedCookies, event.target.value as Cookie]);
    }
  };

  return (
    <Modal isOpen={!cookies[COOKIE_CONSENTDATE]} isCentered onClose={() => {}}>
      <ModalOverlay />
      <ModalContent mb="4">
        <ModalHeader display="flex" justifyContent="space-between">
          {t("cookie.title")}
          <LanguageSwitcher />
        </ModalHeader>
        <ModalBody>
          <Box mb="8">
            {t("cookie.subtitle")}
            <Link to="/about" color={theme.colors.primary}>
              <b> {t("cookie.linkText")}.</b>
            </Link>
          </Box>
          <CheckboxGroup value={selectedCookies}>
            <Checkbox
              value={COOKIE_NECESSARY as Cookie}
              isChecked={selectedCookies.includes(COOKIE_NECESSARY)}
              isDisabled
              mb="4"
              display="flex"
              alignItems="baseline"
              onChange={toggleCheckboxValue}
            >
              <b> {t("cookie.necessary")}</b> {t("cookie.necessarydescr")}
            </Checkbox>
            <Checkbox
              value={COOKIE_PREFERENCE as Cookie}
              isChecked={selectedCookies.includes(COOKIE_PREFERENCE)}
              colorScheme="teal"
              display="flex"
              alignItems="baseline"
              mb="4"
              onChange={toggleCheckboxValue}
            >
              <span>
                <b> {t("cookie.preference")}</b> {t("cookie.preferencedescr")}
              </span>
            </Checkbox>
            <Checkbox
              value={COOKIE_STATISTICAL as Cookie}
              isChecked={selectedCookies.includes(COOKIE_STATISTICAL)}
              colorScheme="teal"
              mb="4"
              display="flex"
              alignItems="baseline"
              onChange={toggleCheckboxValue}
            >
              <b> {t("cookie.statistical")}</b> {t("cookie.statisticaldescr")}
            </Checkbox>
            <Checkbox
              value={COOKIE_ADS as Cookie}
              isChecked={selectedCookies.includes(COOKIE_ADS)}
              colorScheme="teal"
              mb="4"
              display="flex"
              alignItems="baseline"
              onChange={toggleCheckboxValue}
            >
              <b> {t("cookie.advertising")}</b> {t("cookie.advertisingdescr")}
            </Checkbox>
          </CheckboxGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={concentSelected}
            variant="link"
            color={theme.colors.primary}
            _hover={{
              textDecoration: "none",
            }}
            size="sm"
            mr="4"
          >
            {t("cookie.savepref")}
          </Button>
          <Button
            onClick={concentAll}
            backgroundColor={theme.colors.primary}
            color="white"
            variant="solid"
          >
            {t("cookie.saveall")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
