import { EnumLanguages, IAssetType } from "../entities/DTO-models/subTypes";

export const appendMLToFormData = (
  propertyName: string,
  theFormData: FormData,
  theMLObject?: {
    [lang: string]: string | string[];
  }
) => {
  if (theMLObject) {
    Object.entries(theMLObject).forEach(([lang, transValue]) => {
      if (typeof transValue === "string" && transValue)
        theFormData.append(`${propertyName}[${lang}]`, transValue);
      if (Array.isArray(transValue))
        transValue.map((val, i) => {
          theFormData.append(`${propertyName}[${lang}][${i}]`, val);
        });
    });
  }
};
