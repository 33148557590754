export const SearchIcon = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <title>Icon/Search</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.2-Gebieden---kaart-view"
        transform="translate(-1103.000000, -280.000000)"
        fill="#36425B"
        fillRule="nonzero"
      >
        <g id="Shape" transform="translate(1103.000000, 280.000000)">
          <path d="M15.7244714,14.4868238 L12.8549799,11.6365959 C15.0954556,8.76631817 14.8597712,4.67850166 12.2844822,2.10307505 C10.9284814,0.747074216 9.11935329,0 7.19377864,0 C5.26726046,0 3.45899726,0.74711353 2.10307505,2.10307505 C0.747074216,3.45907589 0,5.26820399 0,7.19377864 C0,9.12029682 0.74711353,10.92856 2.10307505,12.2844822 C3.45907589,13.6404831 5.26820399,14.3875573 7.19377864,14.3875573 C8.78628942,14.3875573 10.3589076,13.8569824 11.6165461,12.854174 L14.466774,15.7443051 C14.643378,15.9209091 14.8599088,16 15.0956325,16 C15.3313562,16 15.5478949,15.9017163 15.7244911,15.7443051 C16.0784697,15.4102977 16.0784697,14.8405469 15.7244911,14.486588 L15.7244714,14.4868238 Z M12.6192956,7.19377864 C12.6192956,8.6480632 12.0495645,10.0048896 11.0267848,11.0268437 C10.0047913,12.0488372 8.62885856,12.6193545 7.19371967,12.6193545 C5.75858078,12.6193545 4.3826087,12.0496235 3.36065457,11.0268437 C2.33866113,10.0048503 1.76814379,8.62891753 1.76814379,7.19377864 C1.76814379,5.73949408 2.33787486,4.38266767 3.36065457,3.36071354 C4.38264801,2.3387201 5.75858078,1.76820276 7.19371967,1.76820276 C8.64800423,1.76820276 10.0048306,2.33793383 11.0267848,3.36071354 C12.0487782,4.38347359 12.6192956,5.73957271 12.6192956,7.19377864 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);
