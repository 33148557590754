import React, { FC, PropsWithChildren, useState } from "react";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  useTheme,
  FormHelperText,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

interface FormFieldProps {
  label?: string;
  error?: string;
  isRequired?: boolean;
  information?: string;
}

export const FormField: FC<PropsWithChildren<FormFieldProps>> = ({
  error,
  children,
  label,
  isRequired = false,
  information,
}) => {
  const [helpTextVisible, setHelpTextVisible] = useState(false);
  const theme = useTheme();
  return (
    <FormControl isInvalid={Boolean(error)} mb="4">
      <Flex alignItems="center" mb={helpTextVisible ? "0" : "2"}>
        {label && (
          <FormLabel mr="1" mb="0">
            {label}
            {isRequired && (
              <span style={{ color: error && "#E53E3E", marginLeft: "2px" }}>
                *
              </span>
            )}
          </FormLabel>
        )}
        {information && (
          <InfoOutlineIcon
            color={theme.colors.text.tertiary}
            onClick={() => setHelpTextVisible(!helpTextVisible)}
          />
        )}
      </Flex>
      {helpTextVisible && (
        <FormHelperText mb="2" mt="0">
          {information}
        </FormHelperText>
      )}
      {children}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
