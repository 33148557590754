import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

import { App } from "./app/App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <Auth0Provider
      domain="mtb-dev.eu.auth0.com"
      clientId="kMhMSebQOZAksLmKsAsNEmUBLIO70TRK"
      authorizationParams={{
        audience: "https://mytravelbuddy.eu:3000",
        redirect_uri: "http://localhost:3002",
        scope: "tenant:guest",
      }}
    >
      <App />
    </Auth0Provider>
  </StrictMode>
);
