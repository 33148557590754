/******************************************
 * area data using MTB-API
 * @author Noël Thoelen
 * @version 1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import {
  ErrorCode,
  IAreaType,
  InSufficientTenantRights,
  InvalidTenantError,
  OptimisticLockError,
  AreaHasRelatedDataError,
  IAreaListType,
} from "..";
import { IAreaLookupResponse } from "../entities/DTO-models/subTypes";
import { appendIAssetTypeData } from "../helpers/appendIAssetTypeData";

export class AreaService {
  /***************************************
   * @description get a list of areas for a specific tenant
   * @link : ../api/tenant/kkk/area
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @param searchVal Optional - pass a search key - only area matching that key will be returned
   * @param areaId Optional - pas an area Id - only the specific area will be returned
   * @Returns IAraListType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the endPoint can only be used by OWNER role or higher
   * - InvalidTenantError | an invalid tenantKey has been passed
   * - general Error | something went wrong (probably network issues)
   */
  public async getAreaForTenant(
    tenantKey: string,
    searchVal?: string,
    areaId?: number
  ): Promise<IAreaListType[]> {
    try {
      let url = "";
      if (searchVal) {
        url = `/tenant/${tenantKey}/area?search=${searchVal}`;
      } else if (areaId) {
        url = `/tenant/${tenantKey}/area?id=${areaId}`;
      } else {
        url = `/tenant/${tenantKey}/area`;
      }
      const json: AxiosResponse = await mtbService.getProtected(url);
      const areaInfo = json.data as IAreaListType[];
      return areaInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description update/create area data
   * @link : ../api/tenant/kkk/area (post/put)
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @param data the data (see type IAreaType)
   * @param areaId the area to be saved
   * @Returns void
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the endPoint can only be used by TENANTADMIN role or higher
   * - OptimisticLockError | the area has been updated  by another user
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async saveAreaForTenant(
    tenantKey: string,
    data: IAreaType,
    areaId?: number
  ) {
    try {
      let url = "";
      const formData = new FormData();
      formData.append("country[value]", data.country.value);
      formData.append("title", data.title);
      appendIAssetTypeData(formData, data.image);
      if (areaId) {
        url = `/tenant/${tenantKey}/area/${areaId}/${data.version}`;
        const json: AxiosResponse = await mtbService.postPutFormDataProtected(
          "put",
          url,
          formData
        );
      } else {
        url = `/tenant/${tenantKey}/area`;
        const json: AxiosResponse = await mtbService.postPutFormDataProtected(
          "post",
          url,
          formData
        );
      }
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description get a area with all detail data
   * @link : ../api/tenant/kkk/area/:areaId
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @param areaId the area to be retrieved
   * @Returns the Area (see IAreaType)
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the endPoint can only be used by TENANTADMIN role or higher
   * - InvalidTenantError | wrong tenant
   * - NotFoundError | invalid areaId -> nothing found
   * - general Error | something went wrong (probably network issues)
   */
  public async getAreaDetailForTenant(
    tenantKey: string,
    areaId: number
  ): Promise<IAreaType> {
    try {
      const url = `/tenant/${tenantKey}/area/${areaId.toString()}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const areaInfo = json.data as IAreaType;
      return areaInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description delete area data
   * @link : ../api/tenant/kkk/area/:areaId/:version (delete)
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @param areaId the area to be deleted
   * @param version the version for optimistic lock check (is returned by the get function)
   * @Returns void
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the endPoint can only be used by TENANTADMIN role or higher
   * - OptimisticLockError | the area has been updated  by another user
   * - AreaHasRelatedDataError | this area still has related data and cannot be deleted - delete related destinations first
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async deleteAreaForTenant(
    tenantKey: string,
    areaId: number,
    version: number
  ) {
    try {
      const url = `/tenant/${tenantKey}/area/${areaId.toString()}/${version.toString()}`;
      const json: AxiosResponse = await mtbService.deleteProtected(url);
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasRelatedData:
          throw new AreaHasRelatedDataError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description function to populate a dropdown selection box to select an area
   * @link : ../api/tenant/kkk/area/lookuplist
   *
   * @param tenantKey The tenantKey of the current tenant (stored in Context)
   * @Returns IAreaLookupResponse
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | protection is needed (at least GUEST token)
   * - InvalidTenantError | wrong tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getAreaLookupListTenant(
    tenantKey: string
  ): Promise<IAreaLookupResponse[]> {
    try {
      const url = `/tenant/${tenantKey}/area/lookupList`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const areaList = json.data as IAreaLookupResponse[];
      return areaList;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.NotFound:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
