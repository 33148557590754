/******************************************
 * urbanisation data using MTB-API
 * 12/08/2022 - Noël Thoelen - v1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import { IUrbanisationType, ErrorCode } from "..";

export class InvalidTenantError extends Error {}
export class InSufficientTenantRights extends Error {}

export class UrbanisationService {
  /***************************************
   * @description Get basic urbanisation list (lookup values)
   * @link : ../api/tenant/{tenantKey}/urbanisation/lookupList
   * @see http://localhost:3001/docs/#/urbanisations/GetUrbanisationsLookupList
   *
   * @param tenantKey tenant where we will work on
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | not enoug rights to fetch the info
   * - NotFoundError | Invalid tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getUrbanisationLookupForTenant(
    tenantKey: string
  ): Promise<IUrbanisationType[]> {
    try {
      const url = `/tenant/${tenantKey}/urbanisation/lookuplist`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const urbanisationInfo = json.data as IUrbanisationType[];
      return urbanisationInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.NotFound:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
