import React from "react";
import { Box } from "@chakra-ui/react";

type NotFoundProps = {
  errorMessage?: string
}

export const NotFound = ({errorMessage = "404"}:NotFoundProps) => (
  <Box
    width="100%"
    height="100%"
    background="#0000001a"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box
      width="100%"
      height="100%"
      mixBlendMode="overlay"
      style={{
        backgroundImage:
          "url(" +
          "https://media.giphy.com/media/dIBUemhk5jPd9rfbHf/giphy.gif" +
          ")",
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
      }}
    ></Box>
    <Box fontSize="4xl" padding="20" color="gray" position="absolute">
      {errorMessage}
    </Box>
  </Box>
);
