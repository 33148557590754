import { useMediaQuery } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { NavigationContext } from "./NavigationContext";

export const NavigationProvider = (props: { children: ReactNode }) => {
  const [isLargerThan575] = useMediaQuery("(min-width: 575px)");

  const [isSideBarOpen, setSideBar] = useState(isLargerThan575);
  const toggleSideBar = () => {
    setSideBar(!isSideBarOpen);
  };
  return (
    <NavigationContext.Provider value={{ isSideBarOpen, toggleSideBar }}>
      {props.children}
    </NavigationContext.Provider>
  );
};
