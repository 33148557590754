import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  VStack,
  HStack,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { getRgbaColor } from "@mtb/mtb-style";

interface DataColumn {
  title: string;
  fieldName: string;
}

interface DataRow {
  [key: string]: any;
}

interface DataTableProps {
  columns: DataColumn[];
  data: DataRow[];
  selectedRowKey: string | null;
  onRowSelect: (row: DataRow) => void;
}

export const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  selectedRowKey,
  onRowSelect,
}) => {
  const [isVerySmallMediaScreen] = useMediaQuery("(max-width: 547px)");

  const theme = useTheme();
  const primaryColor = theme.colors.primary;

  // Gradient background color
  const gradientBg = `linear-gradient(135deg, ${getRgbaColor(
    primaryColor,
    0.2
  )}, white)`;

  const textColor = "gray.600"; // Define a lighter gray color for text

  if (isVerySmallMediaScreen) {
    return (
      <VStack spacing={4} align="stretch">
        {data.map((row, index) => (
          <Box
            key={index}
            p={4}
            borderRadius="md"
            bg={row.key === selectedRowKey ? gradientBg : "white"}
            boxShadow="md"
          >
            <VStack align="stretch" spacing={2}>
              {columns.map((column) => (
                <HStack key={column.fieldName} justify="space-between">
                  <Text color={textColor}>{column.title}:</Text>
                  <Text>{row[column.fieldName]}</Text>
                </HStack>
              ))}
              <HStack justify="flex-end">
                <IconButton
                  aria-label="Select Tenant"
                  icon={<ArrowForwardIcon />}
                  onClick={() => onRowSelect(row)}
                  isDisabled={row.key === selectedRowKey}
                  variant="outline"
                  colorScheme={row.key === selectedRowKey ? "teal" : "gray"}
                />
              </HStack>
            </VStack>
          </Box>
        ))}
      </VStack>
    );
  }

  return (
    <Box overflowX="auto" width="100%">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={column.fieldName}>{column.title}</Th>
            ))}
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) => (
            <Tr
              key={index}
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
              bg={row.key === selectedRowKey ? gradientBg : "white"}
            >
              {columns.map((column) => (
                <Td key={column.fieldName} color={textColor}>
                  {row[column.fieldName]}
                </Td>
              ))}
              <Td textAlign="right">
                <IconButton
                  aria-label="Select Tenant"
                  icon={<ArrowForwardIcon />}
                  onClick={() => onRowSelect(row)}
                  isDisabled={row.key === selectedRowKey}
                  variant="outline"
                  colorScheme={row.key === selectedRowKey ? "teal" : "gray"}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
