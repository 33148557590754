import React, { FC, PropsWithChildren } from "react";

import { Box, BoxProps, useTheme } from "@chakra-ui/react";

type CardProps = BoxProps & {
  customShadow?: boolean;
  cursorPointer?: boolean;
  customPadding?: string;
};

type IHeaderProps = {};
type IContentProps = {
  customPadding?: string;
  onClick?: () => void;
};

interface ICardComposition {
  Header: React.FC<IHeaderProps & PropsWithChildren & BoxProps>;
  Content: React.FC<IContentProps & PropsWithChildren & BoxProps>;
}

export const Card: FC<CardProps> & ICardComposition = ({
  customShadow,
  cursorPointer,
  customPadding,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box
      borderRadius="12px"
      bg={theme.colors.backgrounds.primary}
      boxShadow={customShadow && theme.shadows.card}
      padding={customPadding || "0"}
      _hover={{ cursor: cursorPointer ? "pointer" : "default" }}
      {...props}
    ></Box>
  );
};

const Header: FC<BoxProps & PropsWithChildren> = ({ children, ...props }) => {
  return (
    <Box borderRadius="12px" {...props}>
      {children}
    </Box>
  );
};

const Content: FC<BoxProps & IContentProps & PropsWithChildren> = ({
  children,
  customPadding,
  onClick,
}) => {
  return (
    <Box padding={customPadding || 0} onClick={onClick && onClick}>
      {children}
    </Box>
  );
};

Card.Header = Header;
Card.Content = Content;
