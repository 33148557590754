import { FC } from "react";

import { Box, Center, Heading, Image, Text } from "@chakra-ui/react";

type EmptyProps = {
  title?: string;
  message?: string;
  icon?: "info" | "location";
};

export const Empty: FC<EmptyProps> = ({
  title = "",
  message = "",
  icon = "info",
}) => {
  return (
    <Box py="14" px="1" display="flex" flexDirection="column">
      <Center mb="6">
        {icon === "info" && (
          <svg
            width="63px"
            height="80px"
            viewBox="0 0 63 80"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <title>empty state - infokaarten</title>
            <defs>
              <polygon
                id="path-1"
                points="0 0 53.98532 0 53.98532 76.2918088 0 76.2918088"
              ></polygon>
              <polygon
                id="path-3"
                points="0 0 55.7613977 0 55.7613977 76.2914036 0 76.2914036"
              ></polygon>
            </defs>
            <g
              id="Design"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="1.0-Empty-state"
                transform="translate(-252.000000, -379.000000)"
              >
                <g
                  id="empty-state---infokaarten"
                  transform="translate(252.000000, 379.000000)"
                >
                  <g id="Group-3" transform="translate(8.137220, 0.000000)">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path
                      d="M53.98532,51.9670561 C53.98532,34.268307 41.5024639,12.7134636 26.1042765,3.82342603 C18.4224129,-0.611599297 11.4662807,-1.05751404 6.42406699,1.80750543 C1.35842035,4.68561977 0,5.55263793 0,5.55263793 C0,5.55263793 6.28415865,50.6968538 17.4382301,61.3264413 L26.1042765,76.2918088 L34.7710121,71.3336779 L39.1791585,73.7665667 C39.1791585,73.7665667 46.0422482,69.7809022 46.3537682,69.5831007 C51.0837727,66.5733485 53.98532,60.4987077 53.98532,51.9670561"
                      id="Fill-1"
                      fill="#232D41"
                      mask="url(#mask-2)"
                    ></path>
                  </g>
                  <g id="Group-6" transform="translate(0.000000, 3.708596)">
                    <mask id="mask-4" fill="white">
                      <use xlinkHref="#path-3"></use>
                    </mask>
                    <g id="Clip-5"></g>
                    <path
                      d="M55.7613977,51.9669954 C55.7613977,34.2682463 43.2785416,12.713403 27.8803543,3.82336539 C12.4821669,-5.06667217 0,2.07416659 0,19.7729157 C0,33.9932541 8.0602364,50.6967931 19.2143079,61.3263807 L27.8803543,76.2917482 L36.5470898,71.3336173 C47.7004721,73.5824887 55.7613977,66.1873338 55.7613977,51.9669954"
                      id="Fill-4"
                      fill="#36425B"
                      mask="url(#mask-4)"
                    ></path>
                  </g>
                  <path
                    d="M48.9899721,51.7661532 C48.9899721,65.1663393 39.5389228,70.5731417 27.8803543,63.8423791 C16.2224749,57.1116165 6.77142562,40.7912747 6.77142562,27.3910886 C6.77142562,13.9909025 16.2224749,8.58410007 27.8803543,15.3148627 C39.5389228,22.0456253 48.9899721,38.3659671 48.9899721,51.7661532"
                    id="Fill-7"
                    fill="#FFFFFF"
                  ></path>
                  <polygon
                    id="Fill-9"
                    fill="#11D3B7"
                    points="30.9278059 55.7085358 24.8331783 52.1901513 24.8331783 35.2957028 30.9278059 38.8140873"
                  ></polygon>
                  <path
                    d="M31.4655226,31.3983942 C31.4655226,33.6741445 29.8603673,34.5921638 27.8802853,33.4494641 C25.9008925,32.3060753 24.2957373,29.5347876 24.2957373,27.2590372 C24.2957373,24.9832868 25.9008925,24.0652676 27.8802853,25.2079672 C29.8603673,26.351356 31.4655226,29.1226438 31.4655226,31.3983942"
                    id="Fill-11"
                    fill="#11D3B7"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        )}
        {icon === "location" && (
          <svg
            width="220px"
            height="167px"
            viewBox="0 0 220 167"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <title>empty state - gebieden</title>
            <defs>
              <polygon
                id="path-1"
                points="0 0 219.6619 0 219.6619 125.8357 0 125.8357"
              ></polygon>
            </defs>
            <g
              id="Design"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="1.0-Empty-state"
                transform="translate(-789.000000, -410.000000)"
              >
                <g
                  id="empty-state---gebieden"
                  transform="translate(789.000000, 410.000000)"
                >
                  <g id="Group-3" transform="translate(0.000000, 40.366669)">
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <polygon
                      id="Fill-1"
                      fillOpacity="0.512031182"
                      fill="#E5E7EA"
                      mask="url(#mask-2)"
                      points="219.662 55.792 98.039 125.836 0 70.044 121.623 0"
                    ></polygon>
                  </g>
                  <path
                    d="M33.8779,79.0354694 L58.2449,45.3294694 C58.2509,45.3284694 82.6269,50.8644694 82.6269,50.8644694 L112.4129,5.52146938 L213.5949,61.8624694 L213.5949,63.7764694 L183.8099,109.118469 C183.8089,109.118469 159.4299,103.581469 159.4299,103.581469 L135.0389,137.282469 L108.3029,124.083469 L7.1199,65.8294694 L7.1199,63.9154694 L33.8779,79.0354694 Z"
                    id="Fill-4"
                    fill="#7F848E"
                  ></path>
                  <polygon
                    id="Fill-6"
                    fill="#ADB2BC"
                    points="159.4304 101.666969 58.2434 43.4119694 33.8564 77.1139694 135.0384 135.367969"
                  ></polygon>
                  <polygon
                    id="Fill-8"
                    fill="#9096A1"
                    points="183.8106 107.205369 82.6276 48.9503694 58.2436 43.4123694 159.4216 101.666369"
                  ></polygon>
                  <polygon
                    id="Fill-10"
                    fill="#D3D7E0"
                    points="135.0388 135.368469 33.8558 77.1144694 7.1208 63.9154694 108.3028 122.169469"
                  ></polygon>
                  <polygon
                    id="Fill-12"
                    fill="#ADB2BC"
                    points="213.595 61.8622694 112.412 3.60826938 82.627 48.9512694 183.809 107.205269"
                  ></polygon>
                  <polygon
                    id="Fill-14"
                    fill="#D3D7E0"
                    points="111.7407 72.5937694 109.9807 72.1947694 138.3457 88.5297694 147.9407 90.7817694"
                  ></polygon>
                  <polygon
                    id="Fill-16"
                    fill="#D3D7E0"
                    points="131.9789 77.3996694 118.0529 74.1916694 153.7619 92.0616694 159.9169 93.4386694"
                  ></polygon>
                  <polygon
                    id="Fill-18"
                    fill="#D3D7E0"
                    points="165.1962 96.4900694 143.5032 91.4860694 162.4972 102.371069 183.8102 107.205069"
                  ></polygon>
                  <polygon
                    id="Fill-20"
                    fill="#D3D7E0"
                    points="82.6273 48.9508694 61.3103 44.1168694 79.4003 54.5398694 100.7503 59.3868694"
                  ></polygon>
                  <path
                    d="M41.6903,81.6149694 L52.1773,72.4479694 L62.3373,76.0249694 C62.3373,76.0249694 64.3543,76.6029694 67.6823,77.9219694 C71.0103,79.2419694 77.5103,81.6149694 77.5103,81.6149694 L96.6583,80.8819694 L101.6773,68.4199694 L105.1943,69.4129694 L126.6643,74.3039694 L130.1233,68.9009694 L139.2603,66.2499694 L158.9843,60.2659694 L163.0793,56.8389694 L174.8023,51.7919694 L179.3643,61.0399694 L181.0463,66.4479694 C181.0463,66.4479694 182.1513,76.6589694 182.0393,76.5339694 C181.9273,76.4089694 172.3963,77.6279694 172.3963,77.6279694 L166.0003,77.7219694 L152.8023,74.3039694 L156.8443,66.3219694 L134.8403,72.9969694 L132.0013,77.4019694 L107.0323,71.5029694 L101.4743,85.5809694 L94.5843,86.0919694 L77.1793,88.2449694 L79.4943,90.7819694 L77.4943,102.226969 L41.6903,81.6149694 Z"
                    id="Fill-22"
                    fill="#36425B"
                  ></path>
                  <polygon
                    id="Fill-24"
                    fill="#D3D7E0"
                    points="105.9766 62.4032694 84.5526 57.5352694 105.1946 69.4132694 126.6636 74.3042694"
                  ></polygon>
                  <polygon
                    id="Fill-26"
                    fill="#F4F5F6"
                    points="140.8558 93.3615694 111.8308 118.662569 136.6508 132.905569 158.2698 103.382569"
                  ></polygon>
                  <polygon
                    id="Fill-28"
                    fill="#F0F3F8"
                    points="107.023 115.852169 135.868 90.4591694 106.26 73.4681694 92.751 107.662169"
                  ></polygon>
                  <path
                    d="M48.2061,85.4217694 L70.6201,98.2847694 C74.1271,93.4967694 71.9511,86.7167694 65.7711,83.1707694 C59.5921,79.6237694 51.7121,80.6337694 48.2061,85.4217694"
                    id="Fill-30"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M43.4586,82.6319694 C43.4586,82.6319694 43.8796,81.9619694 44.1186,81.6359694 C47.8766,76.5039694 55.2156,74.4799694 62.3376,76.0249694 L76.6786,56.4399694 L56.7876,44.9829694 L33.8696,77.1169694 L43.4586,82.6319694 Z"
                    id="Fill-32"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M100.8469,70.3620694 L81.4149,59.1690694 L67.6829,77.9220694 C68.0409,78.1020694 68.4019,78.2770694 68.7529,78.4780694 C71.1929,79.8790694 73.1699,81.6460694 74.6669,83.6020694 L96.6589,80.8820694 L100.8469,70.3620694 Z"
                    id="Fill-34"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M77.1792,88.2447694 C78.4842,92.1357694 78.0512,96.2657694 75.5532,99.6757694 C75.2972,100.025769 74.8172,100.690769 74.8172,100.690769 L86.1472,107.192769 L94.5842,86.0917694 L77.1792,88.2447694 Z"
                    id="Fill-36"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M181.0611,66.7955694 C181.0611,73.0855694 175.6631,78.1845694 169.0051,78.1845694 C162.3471,78.1845694 156.9491,73.0855694 156.9491,66.7955694 C156.9491,60.5055694 162.3471,55.4065694 169.0051,55.4065694 C175.6631,55.4065694 181.0611,60.5055694 181.0611,66.7955694"
                    id="Fill-38"
                    fill="#36425B"
                  ></path>
                  <polygon
                    id="Fill-40"
                    fill="#F0F3F8"
                    points="139.2605 66.2497694 158.9845 60.2667694 150.7665 48.6877694"
                  ></polygon>
                  <path
                    d="M172.5764,61.3453694 C169.3164,59.4773694 165.0624,60.4023694 163.0934,63.4093694 C161.1244,66.4133694 162.1744,70.3783694 165.4344,72.2463694 C168.6934,74.1143694 172.9474,73.1873694 174.9164,70.1833694 C176.8854,67.1773694 175.8354,63.2123694 172.5764,61.3453694"
                    id="Fill-42"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M162.7086,76.4061694 C158.8226,74.1801694 156.7266,70.2631694 156.8436,66.3221694 L134.8396,72.9971694 L131.9706,77.3941694 L159.9116,93.4351694 L166.0006,77.7211694 C164.8686,77.4411694 163.7586,77.0081694 162.7086,76.4061694"
                    id="Fill-44"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M209.7834,59.6607694 L181.0464,66.4477694 C181.1454,68.5967694 180.5934,70.7857694 179.3104,72.7447694 C177.6894,75.2187694 175.1854,76.8807694 172.3964,77.6287694 L165.1964,96.4897694 L183.8104,107.205769 L213.6074,61.8517694 L209.7834,59.6607694 Z"
                    id="Fill-46"
                    fill="#F0F3F8"
                  ></path>
                  <path
                    d="M163.079,56.8392694 C166.768,54.9922694 171.41,54.9752694 175.267,57.1852694 C177.004,58.1812694 178.378,59.5172694 179.364,61.0392694 L202.638,55.5432694 L161.672,31.9562694 L153.882,43.8802694 L163.079,56.8392694 Z"
                    id="Fill-48"
                    fill="#F0F3F8"
                  ></path>
                  <polygon
                    id="Fill-50"
                    fill="#F0F3F8"
                    points="112.4036 3.60626938 111.4136 5.11826938 110.6816 22.9032694 137.2136 17.8222694"
                  ></polygon>
                  <polygon
                    id="Fill-52"
                    fill="#F0F3F8"
                    points="156.3018 28.8529694 144.5608 22.1259694 110.2228 28.7019694 109.0828 56.3869694 135.5078 60.5909694"
                  ></polygon>
                  <polygon
                    id="Fill-54"
                    fill="#F0F3F8"
                    points="103.5998 44.0716694 104.7908 15.1266694 87.1528 42.0476694"
                  ></polygon>
                  <polygon
                    id="Fill-56"
                    fill="#F0F3F8"
                    points="106.602 61.8090694 105.961 62.3870694 126.664 74.3040694 132.094 65.8240694"
                  ></polygon>
                  <polygon
                    id="Fill-58"
                    fill="#F0F3F8"
                    points="82.6274 48.9509694 100.7514 59.3869694 102.9174 57.1909694 103.2254 49.7049694 83.7084 47.2909694"
                  ></polygon>
                  <polygon
                    id="Fill-60"
                    fill="#EA687E"
                    points="56.9061 89.9558694 56.6381 89.9648694 56.6861 89.7628694 56.8971 89.7628694"
                  ></polygon>
                  <path
                    d="M67.3998,50.1432694 C59.6398,46.6132694 53.1578,35.3862694 53.9808,26.9012694 C54.5328,21.2042694 59.5318,18.3172694 64.7418,20.6882694 C72.5018,24.2182694 78.9838,35.4452694 78.1618,43.9302694 C77.6098,49.6272694 72.6098,52.5132694 67.3998,50.1432694 M66.0708,3.57926938 C51.5978,-4.77673062 39.8648,1.99626938 39.8648,18.7082694 C39.8648,20.9782694 40.4028,24.7882694 41.1778,27.9242694 C42.7438,34.2572694 44.7088,40.5202694 47.6548,46.8222694 L65.9398,84.7642694 L84.0368,67.0482694 C86.9848,64.1492694 89.3538,60.4412694 90.9198,55.9152694 C91.6958,53.6742694 92.2768,51.2372694 92.2768,48.9682694 C92.2768,32.2562694 80.5448,11.9352694 66.0708,3.57926938"
                    id="Fill-62"
                    fill="#07BAA0"
                  ></path>
                  <path
                    d="M65.7877,84.8796694 L57.0777,89.9086694 C56.8437,90.0436694 56.5517,89.8746694 56.5517,89.6056694 L56.5517,82.0376694 C56.5517,82.0156694 56.5637,81.9946694 56.5837,81.9836694 L65.9737,76.5616694 C66.0157,76.5376694 66.0667,76.5676694 66.0667,76.6156694 L66.0667,84.3956694 C66.0667,84.5956694 65.9607,84.7796694 65.7877,84.8796694"
                    id="Fill-64"
                    fill="#07BAA0"
                  ></path>
                  <polygon
                    id="Fill-66"
                    fill="#07BAA0"
                    points="46.9973 10.3026694 37.4823 15.7956694 37.4823 7.58566938 46.9973 2.09266938"
                  ></polygon>
                  <path
                    d="M56.4681,54.9003694 C49.8231,51.0083694 44.4441,41.6933694 44.3961,33.9923694 C44.3491,26.4253694 50.1161,23.0953694 56.6461,26.9203694 C63.2921,30.8113694 68.6701,40.1273694 68.7181,47.8283694 C68.7651,55.3953694 62.9981,58.7243694 56.4681,54.9003694 M56.5571,9.07336938 C42.0841,0.717369378 30.3511,7.49136938 30.3511,24.2023694 C30.3511,26.4723694 30.9331,29.5813694 31.7091,32.7173694 C33.2751,39.0503694 35.6431,45.4923694 38.5901,51.7943694 L56.3441,89.7633694 C56.4461,89.9823694 56.7341,90.0343694 56.9061,89.8643694 L74.5231,72.5423694 C77.4711,69.6433694 79.8401,65.9353694 81.4061,61.4103694 C82.1821,59.1693694 82.7631,56.7323694 82.7631,54.4633694 C82.7631,37.7513694 71.0311,17.4293694 56.5571,9.07336938"
                    id="Fill-68"
                    fill="#11D3B7"
                  ></path>
                  <polygon
                    id="Fill-70"
                    fill="#EA687E"
                    points="165.5905 69.8464694 165.4165 69.8524694 165.4475 69.7214694 165.5845 69.7214694"
                  ></polygon>
                  <path
                    d="M172.3971,44.0228694 C167.3641,41.7328694 163.1591,34.4508694 163.6931,28.9468694 C164.0511,25.2518694 167.2941,23.3798694 170.6731,24.9168694 C175.7061,27.2068694 179.9111,34.4888694 179.3771,39.9928694 C179.0191,43.6878694 175.7761,45.5598694 172.3971,44.0228694 M171.5351,13.8198694 C162.1471,8.39986938 154.5371,12.7938694 154.5371,23.6328694 C154.5371,25.1048694 154.8861,27.5768694 155.3891,29.6108694 C156.4051,33.7188694 157.6791,37.7808694 159.5901,41.8688694 L171.4501,66.4788694 L183.1881,54.9878694 C185.1001,53.1078694 186.6371,50.7018694 187.6531,47.7668694 C188.1561,46.3128694 188.5331,44.7328694 188.5331,43.2608694 C188.5331,32.4208694 180.9231,19.2398694 171.5351,13.8198694"
                    id="Fill-72"
                    fill="#07BAA0"
                  ></path>
                  <path
                    d="M171.3514,66.5538694 L165.7014,69.8158694 C165.5504,69.9028694 165.3614,69.7938694 165.3614,69.6188694 L165.3614,64.7098694 C165.3814,64.6748694 171.4724,61.1588694 171.4724,61.1588694 C171.4994,61.1428694 171.5324,61.1628694 171.5324,61.1938694 L171.5324,66.2398694 C171.5324,66.3698694 171.4634,66.4888694 171.3514,66.5538694"
                    id="Fill-74"
                    fill="#07BAA0"
                  ></path>
                  <polygon
                    id="Fill-76"
                    fill="#07BAA0"
                    points="159.1634 18.1809694 152.9914 21.7439694 152.9914 16.4189694 159.1634 12.8559694"
                  ></polygon>
                  <path
                    d="M165.3064,47.1084694 C160.9954,44.5834694 157.5074,38.5424694 157.4764,33.5474694 C157.4454,28.6384694 161.1864,26.4794694 165.4224,28.9594694 C169.7324,31.4834694 173.2214,37.5254694 173.2524,42.5214694 C173.2824,47.4294694 169.5424,49.5884694 165.3064,47.1084694 M165.3644,17.3834694 C155.9764,11.9634694 148.3664,16.3574694 148.3664,27.1974694 C148.3664,28.6694694 148.7434,30.6854694 149.2464,32.7194694 C150.2624,36.8274694 151.7984,41.0064694 153.7104,45.0934694 L165.2254,69.7214694 C165.2924,69.8634694 165.4794,69.8974694 165.5904,69.7874694 L177.0174,58.5514694 C178.9294,56.6714694 180.4664,54.2664694 181.4824,51.3304694 C181.9854,49.8774694 182.3624,48.2964694 182.3624,46.8244694 C182.3624,35.9844694 174.7524,22.8034694 165.3644,17.3834694"
                    id="Fill-78"
                    fill="#11D3B7"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        )}
      </Center>

      <Heading
        size="md"
        textAlign="center"
        flex="1"
        width="70%"
        alignSelf="center"
        letterSpacing="wide"
        mb="2"
      >
        {title}
      </Heading>
      <Text
        color="gray.400"
        textAlign="center"
        fontSize="sm"
        letterSpacing="wide"
        flex="1"
        width="70%"
        alignSelf="center"
      >
        {message}
      </Text>
    </Box>
  );
};
