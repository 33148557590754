import { useParams } from "react-router-dom";

import { AlertsAPIContext, TenantContext } from "@mtb/mtb-core";
import {
  DestinationService,
  InSufficientTenantRights,
  InvalidTenantError,
} from "@mtb/mtb-requests";
import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { DestinationContext, DestinationType } from "./DestinationContext";
import { AlertNotificationType } from "@mtb/mtb-core";
import { useTranslation } from "react-i18next";

export const DestinationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { destinationId } = useParams();
  const { tenant } = useContext(TenantContext);
  const { t } = useTranslation("destinations");
  const { addAlert } = useContext(AlertsAPIContext);

  const [destination, setDestinationContext] = useState<DestinationType | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const setDestination = (destination: DestinationType | null) => {
    setDestinationContext(destination);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const destinationService = new DestinationService();
        const destination = await destinationService.getDestinationForTenant(
          tenant!.tenantKey,
          Number(destinationId)
        );

        if (destination) {
          setDestinationContext({
            id: destination.id || 1,
            title: destination.name,
            address: `${destination.city} ${destination.street}`,
          });
        }
      } catch (error) {
        let alertConfig: AlertNotificationType;

        if (error instanceof InSufficientTenantRights) {
          alertConfig = {
            type: "error",
            title: t("info.alertTitle"),
            description: t("info.rightsError"),
          };
        } else if (error instanceof InvalidTenantError) {
          alertConfig = {
            type: "error",
            title: t("info.alertTitle"),
            description: t("info.invalidtenant", {
              key: tenant?.tenantKey,
            }),
          };
        } else {
          alertConfig = {
            type: "error",
            title: t("info.alertfail"),
            description: t("info.loadError"),
          };
        }
        addAlert({ ...alertConfig, duration: 10000 });
        setDestinationContext(null);
      } finally {
        setLoading(false);
      }
    };

    const shouldFetchData = destinationId && !destination?.id;

    if (shouldFetchData) {
      fetchData();
    }
  }, [destinationId, destination]);

  const contextValue = {
    destination,
    setDestination,
  };

  return (
    <DestinationContext.Provider value={contextValue}>
      {children}
    </DestinationContext.Provider>
  );
};
