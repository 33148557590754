import React, { FC, useContext } from "react";

import {
  Box,
  Flex,
  Button,
  Menu,
  Stack,
  Text,
  useTheme,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon, WarningTwoIcon } from "@chakra-ui/icons";

import {
  MyTravelBuddyLogoWithText,
  MyTravelBuddyLogo,
} from "../../MyTravelBuddyLogo";
import { NavLinkType } from "../NavLink/types";
import { NavLink } from "../NavLink/NavLink";

import { TopNavType } from "./types";
import { NavigationContext } from "../NavigationContext";

type TopNavProps = {
  topNav: TopNavType;
};

export const TopNav: FC<TopNavProps> = ({
  topNav: { logo, warning, navLinks, rightComponents },
}) => {
  const { toggleSideBar } = useContext(NavigationContext);
  const theme = useTheme();
  const [isSmallMediaScreen] = useMediaQuery("(max-width: 547px)");

  return (
    <Box
      bg={theme.colors.backgrounds.primary}
      boxShadow="sm"
      zIndex="docked"
      position="fixed"
      width="100%"
    >
      {/* {warning && (
        <Flex
          px="6"
          minHeight={6}
          background={theme.colors.primary}
          justifyContent="center"
          zIndex="toast"
          width="100%"
        >
          <Text
            fontSize="sm"
            display="flex"
            alignItems="center"
            color="white"
            textAlign="center"
          >
            {warning}
          </Text>
        </Flex>
      )} */}
      <Flex
        h={20}
        alignItems={"center"}
        justifyContent={"space-between"}
        pr="20px"
        pl="4px"
      >
        <Flex alignItems={"center"}>
          <Button
            variant="unstyled"
            width="60px"
            height="60px"
            backgroundColor="transparent"
            rounded="none"
            onClick={() => toggleSideBar()}
          >
            <HamburgerIcon color="gray.300" boxSize="5" />
          </Button>
          <Box>
            {isSmallMediaScreen ? (
              <MyTravelBuddyLogo />
            ) : (
              <MyTravelBuddyLogoWithText />
            )}
          </Box>
        </Flex>
        <Flex alignItems={"center"}>
          <Stack direction={"row"}>
            {/* Links */}
            <Menu>
              {navLinks &&
                navLinks.map((navLink: NavLinkType) => (
                  <NavLink navLink={navLink} />
                ))}
              {rightComponents}
            </Menu>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};
