import { FC, ReactNode } from "react";
import ReactPlayer from "react-player";
import { Box, Image } from "@chakra-ui/react";
import { IAssetType } from "@mtb/mtb-requests";
import { img_emptyArea } from "@mtb/mtb-style";

export type AssetProps = {
  /** Asset to be displayed. */
  asset: IAssetType;
  /** Border radius value to be applied to the component. */
  borderRadius?: string;
  /** Activate or desactivate the video controls. */
  activateVideoControls?: boolean;
  /* fallback image in case the asset doesn't return anything */
  fallbackAsset?: string;
};

/** This component will receive an image/video asset and will display it.
 * The type of the component should be URL.
 */

export const Asset: FC<AssetProps> = ({
  asset,
  borderRadius,
  activateVideoControls = false,
  fallbackAsset,
}) => {
  let JSX = null;

  const { url, mimeType, variant, width, height } = asset;
  let src = url;

  if ((!mimeType || mimeType === "video") && url) {
    JSX = (
      <ReactPlayer
        style={{ borderRadius: borderRadius }}
        width="100%"
        height="100%"
        volume={0}
        muted={true}
        controls={activateVideoControls}
        loop={true}
        playing={true}
        playsinline={true}
        url={url}
      />
    );
  }

  if (mimeType && mimeType === "image") {
    let srcSet = "";

    if (variant?.small) {
      srcSet = srcSet + `${variant.small.url} 1x`;
      src = variant.small.url;
    }
    if (variant?.medium) {
      srcSet = srcSet + `, ${variant.medium.url} 1.5x`;
      src = src ? src : variant.medium.url;
    }
    if (variant?.large) {
      srcSet = srcSet + `, ${variant.large.url} 2x`;
      src = src ? src : variant.large.url;
    }

    JSX = (
      <Image
        src={src}
        srcSet={srcSet}
        borderRadius={borderRadius}
        fallbackSrc={fallbackAsset}
        width={width}
        height={height}
        objectFit="contain"
        fallback={
          <Box>
            <img width="140px" src={fallbackAsset} />
          </Box>
        }
      />
    );
  }

  return JSX;
};
