import React, { ReactNode, FC, ReactChild, useState, useContext } from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

import { TopNav } from "./Navigation/TopNav/TopNav";
import { SideNav } from "./Navigation/SideNav/SideNav";
import { LayoutType } from "./types";
import { NavigationProvider } from "./Navigation/NavigationProvider";
import { useTheme } from "@chakra-ui/react";
import { IconContext } from "react-icons";
import { NavigationContext } from "./Navigation/NavigationContext";
import { ContentContainer } from "./ContentContainer";

type LayoutProps = {
  layout: LayoutType;
  children: ReactChild;
};

export const Layout: FC<LayoutProps> = ({
  layout: { topNav, sideNav },
  children,
}) => {
  const theme = useTheme();
  const { isSideBarOpen, toggleSideBar } = useContext(NavigationContext);

  return (
    <NavigationProvider>
      <Flex minH="100vh" direction="column">
        <TopNav topNav={topNav} />
        <Flex flex="1" marginTop="80px">
          <IconContext.Provider
            value={{ color: theme.colors.backgrounds.terciary, size: "20" }}
          >
            {sideNav && <SideNav sideNav={sideNav} />}
          </IconContext.Provider>
          <ContentContainer>{children}</ContentContainer>
        </Flex>
      </Flex>
    </NavigationProvider>
  );
};
