/******************************************
 * contact data using MTB-API
 * @author Noël Thoelen
 * @version 1
 */
import { AxiosResponse, AxiosError } from "axios";
import { mtbService } from "./mtbService";
import {
  ErrorCode,
  IContactListType,
  IContactType,
  InSufficientTenantRights,
  InvalidDataError,
  InvalidTenantError,
  NotFoundError,
  OptimisticLockError,
} from "..";
import { appendIAssetTypeData } from "../helpers/appendIAssetTypeData";
import { appendMLToFormData } from "../helpers/appendMLToFormData";

export class ContactService {
  /***************************************
   * @description get contact info for a specific contact
   * @link ../api/tenant/kkk/contact/:contactId
   * @see http://localhost:3001/docs/#/contacts/GetContact
   *
   * @param tenantKey The tenant key of the current tenant (stored in Context)
   * @param contactId The unique contact Id for the contact to be fetched
   * @Returns IContactType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the enedPoint can only be used bu OWNER role or higher
   * - InvalidTenantError | an invalid tenantKey has been passed
   * - NotFoundError | an invalid contactId has been passed for this tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async getContact(
    tenantKey: string,
    contactId: number
  ): Promise<IContactType> {
    try {
      const url = `/tenant/${tenantKey}/contact/${contactId}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const contactInfo = json.data as IContactType;
      return contactInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.NotFound:
          throw new NotFoundError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description get a list fo contacts to show in the guest app. This will be based
   *   on the tenant als destination alias. All contacts will be fetched (either linked to
   *   the tenant, area or destination)
   * @link ../api/tenant/kkk/contact/guestOverview?destination=
   *
   * @param tenantKey The tenant key of the current tenant (stored in Context)
   * @param destinationId The unique destination Id (stored on Context))
   * @Returns array of IContactList
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | a specific token for the tenant need to be passed. At least a guest toke should be passed
   * - InvalidTenantError | an invalid tenantKey has been passed
   * - general Error | something went wrong (probably network issues)
   */
  public async getGuestOverview(
    tenantKey: string,
    destinationId?: number
  ): Promise<IContactListType[]> {
    try {
      const url = `/tenant/${tenantKey}/contact/guestOverview?destination=${destinationId}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const contactInfo = json.data as IContactListType[];
      return contactInfo;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description get a list of contacts for the tenant, an area or  a destination
   * @link ../api/tenant/kkk/contact/
   * @see http://localhost:3001/docs/#/contacts/GetContactList
   *
   * @param tenantKey The tenant key of the current tenant (stored in Context)
   * @param search a search string: only contacts matching teh search criteria are returned (name)
   * @param tenantContacts return the contacts linked to the tenant
   * @param areaId only return contacts linked to the passed area with a specific Id
   * @param destinationId only returns contacts linked to the passed destination with a specific destinationId
   * @Returns an array of IContactListType
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the enedPoint can only be used bu OWNER role or higher
   * - InvalidTenantError | an invalid tenantKey has been passed
   * - InvalidDataError | an invaid combination fo search parameters has been passed
   * - general Error | something went wrong (probably network issues)
   */
  public async getContactList(
    tenantKey: string,
    search?: string,
    tenantContacts: boolean = false,
    areaId?: number,
    destinationId?: number
  ): Promise<IContactListType[]> {
    try {
      const urlWithParams = new URL("/", "https://dummy");
      if (search) urlWithParams.searchParams.append("search", search);
      if (tenantContacts)
        urlWithParams.searchParams.append("tenant", tenantContacts.toString());
      if (destinationId)
        urlWithParams.searchParams.append(
          "destination",
          destinationId.toString()
        );
      else if (areaId)
        urlWithParams.searchParams.append("area", areaId.toString());
      const url = `/tenant/${tenantKey}/contact${urlWithParams.search}`;
      const json: AxiosResponse = await mtbService.getProtected(url);
      const contactList = json.data as IContactListType[];
      return contactList;
    } catch (errResponse) {
      const errCode: ErrorCode = errResponse.response?.data
        .errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.DataInconsistancy:
          throw new InvalidDataError();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description save a contact.
   * - new - no contactId is set in the body
   * - existing - see contactId in the body. In this case also the fetched version should be
   *   passed to check optimistic lock
   * @link ../api/tenant/kkk/contact/:contactId/:version
   * @see http://localhost:3001/docs/#/contacts/PutPostContact
   *
   * @param tenantKey The tenant key of the current tenant (stored in Context)
   * @param data the body - see type IContactType
   * @Returns nothing
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the endPoint can only be used bu OWNER role or higher.
   *     Please notice the OWNER role can only update contacts created by himselves
   * - InvalidTenantError | an invalid tenantKey has been passed
   * - InvalidDataError | validation errors occurred. Check the error message
   * - OptimisticLockError | Antoher used did already changed the contact while the user was editing
   * - general Error | something went wrong (probably network issues)
   */ public async saveContact(tenantKey: string, data: IContactType) {
    try {
      let url = "";
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("status", data.status.toString());
      appendIAssetTypeData(formData, data.image, "image");
      formData.append("category", data.category.toString());
      data.phone && formData.append("phone", data.phone);
      data.whatsapp && formData.append("whatsapp", data.whatsapp);
      data.email && formData.append("email", data.email);
      if (data.contactLanguages) {
        data.contactLanguages.forEach((elt, index) => {
          formData.append(`contactLanguages[${index}]`, elt);
        });
      }
      appendMLToFormData("contactInfo", formData, data.contactInfo);
      data.address &&
        data.address.street &&
        formData.append("address[street]", data.address.street);
      data.address &&
        data.address.zipCode &&
        formData.append("address[zipCode]", data.address.zipCode);
      data.address &&
        data.address.city &&
        formData.append("address[city]", data.address.city);
      data.address &&
        data.address.appNo &&
        formData.append("address[appNo]", data.address.appNo);
      data.address &&
        data.address.coordinates &&
        formData.append(
          "address[coordinates][lat]",
          data.address.coordinates!.lat.toString()
        );
      data.address &&
        data.address.coordinates &&
        formData.append(
          "address[coordinates][lng]",
          data.address.coordinates!.lng.toString()
        );
      data.visibleforRoles?.map((role, i) => {
        formData.append(`visibleforRoles[${i}]`, role);
      });
      data.sorting && formData.append("sorting", data.sorting.toString());
      formData.append("isTenantContact", data.isTenantContact.toString());
      if (data.areaIds) {
        data.areaIds.forEach((elt, index) => {
          formData.append(`areaIds[${index}]`, elt.toString());
        });
      }
      if (data.destinationIds) {
        data.destinationIds.forEach((elt, index) => {
          formData.append(`destinationIds[${index}]`, elt.toString());
        });
      }
      data.tenantUserId &&
        formData.append("tenantUserId", data.tenantUserId.toString());
      if (data.id) {
        url = `/tenant/${tenantKey}/contact/${data.id}/${data.version}`;
        const json: AxiosResponse = await mtbService.postPutFormDataProtected(
          "put",
          url,
          formData
        );
      } else {
        url = `/tenant/${tenantKey}/contact`;
        const json: AxiosResponse = await mtbService.postPutFormDataProtected(
          "post",
          url,
          formData
        );
      }
    } catch (errResponse: any) {
      console.log(errResponse);
      const errCode: ErrorCode = errResponse.response?.data
        ?.errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.NotFound:
          throw new NotFoundError();
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        default:
          break;
      }
      throw errResponse;
    }
  }

  /***************************************
   * @description delete a contact.
   * @link ../api/tenant/kkk/contact/:contactId/:version
   * @see http://localhost:3001/docs/#/contacts/DeleteContact
   *
   * @param tenantKey The tenant key of the current tenant (stored in Context)
   * @param contactId The unique contact Id
   * @param version The version whe are working on (for optimisticlock)
   * @Returns nothing
   *
   * #### errors - check instanceOf error to give corresponding feedback to the user
   * - InSufficientTenantRights | the endPoint can only be used bu OWNER role or higher.
   *     Please notice the OWNER role can only delete contacts created by himselves
   * - InvalidTenantError | an invalid tenantKey has been passed
   * - OptimisticLockError | Antoher used did already changed the contact while the user was editing
   * - NotFoundError | unexistinhg contactId within current tenant
   * - general Error | something went wrong (probably network issues)
   */
  public async deleteContact(
    tenantKey: string,
    contactId: Number,
    version: Number
  ) {
    try {
      let url = "";
      url = `/tenant/${tenantKey}/contact/${contactId}/${version}`;
      const json: AxiosResponse = await mtbService.deleteProtected(url);
    } catch (errResponse: any) {
      console.log(errResponse);
      const errCode: ErrorCode = errResponse.response?.data
        ?.errorCode as ErrorCode;
      switch (errCode) {
        case ErrorCode.NotFound:
          throw new NotFoundError();
        case ErrorCode.InsufficientRights:
          throw new InSufficientTenantRights();
        case ErrorCode.InvalidTenantError:
          throw new InvalidTenantError();
        case ErrorCode.RecordHasBeenUpdated:
          throw new OptimisticLockError();
        default:
          break;
      }
      throw errResponse;
    }
  }
}
