import { FC, ReactChild, useContext } from "react";
import {
  FiHome,
  FiCompass,
  FiUsers,
  FiShoppingCart,
  FiSettings,
} from "react-icons/fi";
import { Layout, LayoutType } from "@mtb/mtb-ui";
import { LanguageSwitcher } from "@mtb/mtb-core";
import { UserContext } from "@mtb/mtb-core";
import { TenantContext } from "@mtb/mtb-core";
import { EnumUserRole, rolesCheck } from "@mtb/mtb-requests";
import { useTranslation } from "react-i18next";

import { Box, Center } from "@chakra-ui/react";
import { AvatarMenu } from "./avatar";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { TbUsers } from "react-icons/tb";
import { InfoOutlineIcon } from "@chakra-ui/icons";

type Props = {
  children: ReactChild;
};

export const AdminLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation("common");
  const { user } = useContext(UserContext);
  const { tenant } = useContext(TenantContext);

  let warningText: string | undefined = undefined;
  if (tenant && !tenant.validSubscription) {
    warningText = t("topnav.subsexpired");
  }
  if (
    tenant &&
    tenant.validSubscription &&
    tenant.subscriptionExpireInDays < 30
  ) {
    warningText = t("topnav.subswarning", {
      expdays: tenant.subscriptionExpireInDays.toString(),
    });
  }

  const appLayout: LayoutType = {
    topNav: {
      logo: {
        name: "My Travel Buddy",
        path: "./smth",
      },
      warning: warningText,

      rightComponents: (
        <Center>
          <Box mr="12px">
            <AvatarMenu />
          </Box>
          <LanguageSwitcher />
        </Center>
      ),
    },
    sideNav: {
      linkGroups: [
        {
          name: "sidenav-links",
          navLinkItems: [],
        },
      ],
      rightGroups: [
        {
          name: "sidenav-right",
          navLinkItems: [],
        },
      ],
    },
  };

  if (tenant) {
    // tenantBase
    if (
      rolesCheck(tenant.userRoleInTenant as EnumUserRole, [
        EnumUserRole.ADMIN,
        EnumUserRole.TENANTADMIN,
        EnumUserRole.TENANTCONTRIBUTOR,
        EnumUserRole.OWNER,
      ])
    ) {
      appLayout.sideNav?.linkGroups[0].navLinkItems.push({
        name: t("sidenav.info"),
        path: `/tenants/${tenant.tenantKey}`,
        icon: <MdOutlineSpaceDashboard />,
      });
    }
    // team
    // if (
    //   rolesCheck(tenant.userRoleInTenant as EnumUserRole, [
    //     EnumUserRole.ADMIN,
    //     EnumUserRole.TENANTADMIN,
    //     EnumUserRole.OWNER,
    //   ])
    // ) {
    //   appLayout.sideNav!.linkGroups[0].navLinkItems.push({
    //     name: t("sidenav.team"),
    //     path: `/tenants/${tenant.tenantKey}/team`,
    //     icon: <FiUsers />,
    //   });
    // }
  }
  if (
    user?.isAdminRole ||
    (tenant &&
      rolesCheck(tenant.userRoleInTenant as EnumUserRole, [
        EnumUserRole.ADMIN,
        EnumUserRole.TENANTADMIN,
        EnumUserRole.OWNER,
      ]))
  ) {
    appLayout.sideNav!.linkGroups[0].navLinkItems.push({
      name: t("sidenav.tenant"),
      path: "/tenants",
      icon: (
        <svg
          fill="none"
          height="30px"
          viewBox="0 0 24 24"
          width="30px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="rgb(229, 231, 234)">
            <path d="m12.25 12.5c0 1.1046-.8954 2-2 2-1.10457 0-2-.8954-2-2s.89543-2 2-2c1.1046 0 2 .8954 2 2z" />
            <path
              clipRule="evenodd"
              d="m14.25 4.5 5.75 5.6893v7.8107h-3.5v2h-12.5v-8.0607l6.25-6.24997 1.375 1.375zm4.25 6.3107v5.6893h-2v-4.5607l-3.8143-3.81431 1.5643-1.56434zm-13 1.75v5.9393h1.28545c.24261-1.6962 1.70132-3 3.46455-3 1.7632 0 3.2219 1.3038 3.4646 3h1.2854v-5.9393l-4.75-4.75005z"
              fillRule="evenodd"
            />
          </g>
        </svg>
      ),
    });

    appLayout.sideNav!.linkGroups[0].navLinkItems.push({
      name: t("sidenav.adminusers"),
      path: "/admin/users",
      icon: <TbUsers />,
    });
  }

  appLayout.sideNav?.rightGroups?.push({
    name: "more",
    navLinkItems: [
      {
        name: "About",
        path: "/about",
        icon: (
          <InfoOutlineIcon
            color="rgb(229, 231, 234)"
            width="20px"
            height="20px"
          />
        ),
      },
    ],
  });

  return <Layout layout={appLayout}>{children}</Layout>;
};
