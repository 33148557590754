/***********
 * 01/07/2024 Refactored to use the auth0 IdToken. So if there is still
 *   a cookie left and valid, auth0 will make sure we are still signedin
 *   if not, auth0 will use thr refresh token to get a new access token
 *  See auth0 documentation in the wiki for more info
 */
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "@mtb/mtb-core";
import { AuthenticateService, EnumUserRole } from "@mtb/mtb-requests";
import { Loader, Error } from "@mtb/mtb-ui";
import { useTranslation } from "react-i18next";

type ContextLoaderProps = {
  children: ReactNode;
};
export const UserContextLoader: FC<ContextLoaderProps> = React.memo(
  ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<{
      errorTitle: string;
      errorText: string;
    } | null>(null);
    const { t } = useTranslation("common");
    const { user: contextUser, setUser } = useContext(UserContext);
    const {
      isLoading: isAuth0Loading,
      getIdTokenClaims,
      getAccessTokenSilently,
      user,
    } = useAuth0();

    const loadAuth0Claim = async (): Promise<void> => {
      setLoading(true);
      setError(null);
      try {
        const idt = await getIdTokenClaims();
        console.log("userContextLoader - getIdToken", idt);
        if (idt) {
          const userInfo = {
            userId: String(idt.sub),
            firstName: idt.given_name || idt.name,
            lastName: idt.family_name,
            email: idt.email!,
            photoUrl: idt.picture,
            isAdminRole:
              idt["https://app.mytravelbuddy.eu/userRoleInTenant"] ===
              EnumUserRole.ADMIN,
          };
          // set token (for the case there is no tenant)
          const token = await getAccessTokenSilently();
          console.log("userContextLoader - getaccessToken", token);
          const authSvc = new AuthenticateService();
          token && authSvc.setAuth0AccessToken(token);

          setUser(userInfo);
        } else {
          if (contextUser) {
            setUser(null);
          }
        }
      } catch (error) {
        console.log(error);
        setError({
          errorTitle: t("login.alertfail"),
          errorText: t("login.error"),
        });
        setUser(null);
      }
      setLoading(false);
    };
    useEffect(() => {
      if (!isAuth0Loading && user?.sub !== contextUser?.userId) {
        console.log(
          "**** usercontextloader: useEffect user changed - set new user"
        );
        loadAuth0Claim();
      } else {
        setLoading(false);
      }
    }, [isAuth0Loading, user]);

    if ((!user && isAuth0Loading) || loading) {
      return (
        <Center>
          <Loader />
        </Center>
      );
    }
    if (error) {
      return (
        <Box width="100%" flexDirection="column" padding="5">
          <Heading size="md" flex="1">
            {error.errorTitle}
          </Heading>
          <Text flex="1" color="red">
            {error.errorText}
          </Text>
        </Box>
      );
    }

    return <>{children}</>;
  }
);
