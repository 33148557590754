import React, { FC, useContext, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@chakra-ui/react";

import { SideNavType } from "./types";
import { NavLinkGroupType } from "../NavLinkGroup/types";
import { NavLinkGroup } from "../NavLinkGroup";
import { NavigationContext } from "../NavigationContext";

type SideNavProps = {
  sideNav: SideNavType;
};

export const SideNav: FC<SideNavProps> = ({ sideNav }) => {
  const [isLargerThan860] = useMediaQuery("(min-width: 860px)");
  const [isSmallerThan860] = useMediaQuery("(max-width: 860px)");
  const { isSideBarOpen, toggleSideBar } = useContext(NavigationContext);
  const theme = useTheme();

  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 860 ? true : false,
  });

  useEffect(() => {
    let mediaQuery = window.matchMedia("(max-width: 860px)");
    mediaQuery.addListener(() => {
      if (isSideBarOpen) {
        toggleSideBar();
      }
    });

    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  return (
    <Box
      overflow="hidden"
      position="fixed"
      top="0"
      bottom="0"
      bg={theme.colors.secondary}
      borderRight="1px"
      borderRightColor="gray.200"
      pt="95px"
      width={!isSideBarOpen && isLargerThan860 ? "70px" : "3xs"}
      display={!isSideBarOpen && !isLargerThan860 ? "none" : "flex"}
      flexDirection="column"
      justifyContent="space-between"
      transform={
        !isSideBarOpen && !isLargerThan860
          ? "translateX(-100%)"
          : "translateX(0)"
      }
      transition="transform .3s, width .3s"
      zIndex="9"
    >
      {sideNav.linkGroups.map((linkGroup: NavLinkGroupType) => (
        <NavLinkGroup key={linkGroup.name} navLinkGroup={linkGroup} />
      ))}
      {sideNav?.rightGroups?.map((linkGroup: NavLinkGroupType) => (
        <NavLinkGroup key={linkGroup.name} navLinkGroup={linkGroup} />
      ))}
    </Box>
  );
};
