import { AlertsAPIContext, TenantContext } from "@mtb/mtb-core";
import { AlertNotificationType } from "@mtb/mtb-core";
import {
  AreaService,
  InSufficientTenantRights,
  InvalidTenantError,
} from "@mtb/mtb-requests";
import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AreaContext, AreaType } from "./AreaContext";

export const AreaProvider: FC<PropsWithChildren> = ({ children }) => {
  const { areaId } = useParams();
  const { tenant } = useContext(TenantContext);
  const { t } = useTranslation("areas");

  const [area, setAreaContext] = useState<AreaType | null>(null);
  const [loading, setLoading] = useState(false);
  const { addAlert } = useContext(AlertsAPIContext);

  const setArea = (area: AreaType | null) => {
    setAreaContext(area);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const areaSvc = new AreaService();
        const result = await areaSvc.getAreaForTenant(
          tenant!.tenantKey,
          undefined,
          Number(areaId)
        );

        if (result && result.length === 1) {
          const [areaData] = result;
          setAreaContext({
            areaId: areaData.id,
            title: areaData.title,
            countryCode: areaData.country,
            publishedDestinations: areaData.numberOfPublishedDestinations,
            unpublishedDestinations: areaData.numberOfUnpublishedDestinations,
          });
        }
      } catch (error) {
        let alertConfig: AlertNotificationType;

        if (error instanceof InSufficientTenantRights) {
          alertConfig = {
            type: "error",
            title: t("areaInfo.alertTitle"),
            description: t("areaInfo.rightsError"),
          };
        } else if (error instanceof InvalidTenantError) {
          alertConfig = {
            type: "error",
            title: t("areaInfo.alertTitle"),
            description: t("areaInfo.invalidtenant", {
              key: tenant?.tenantKey,
            }),
          };
        } else {
          alertConfig = {
            type: "error",
            title: t("areaInfo.alertfail"),
            description: t("areaInfo.loadError"),
          };
        }
        addAlert({ ...alertConfig, duration: 10000 });
        setAreaContext(null);
      } finally {
        setLoading(false);
      }
    };

    const shouldFetchData = areaId && !area?.areaId;

    if (shouldFetchData) {
      fetchData();
    }
  }, [areaId, area]);

  const contextValue = {
    area,
    setArea,
    loading,
  };

  return (
    <AreaContext.Provider value={contextValue}>{children}</AreaContext.Provider>
  );
};
